.deleteIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.priceContainer > button {
  border: 1px solid var(--primary-border-color);
  padding-top: var(--f8);
  padding-bottom: var(--f8);
  padding-left: var(--f20);
  background-color: var(--primary-color);
  height: 42px;
  display: flex;
  padding-right: var(--f20);
  align-items: center;
  width: 141px;
  justify-content: center;
  border-radius: var(--f8);
  transition: 0.5s all;
  font-weight: var(--fw700) !important;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.priceContainer > button:hover {
  background-color: #e8450e;
}

.demoDeliverabilityImagContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.demoDeliverabilityImagContainer > h1 {
  align-items: center;
  font-size: 28px;
  margin: 20px 0;
}

@media (max-width: 1300px) {
  .demoDeliverabilityImagContainer > h1 {
    display: flex;
    justify-content: center;

    align-items: center;
    font-size: 24px;
    margin: 10px 0;
  }
}
.deliverabilityTestEmailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.deliverabilityTestEmailContainer > .selectIcontext {
  color: green !important;
}

.deliverabilityImage {
  padding: 40px;
  background-color: #fff;
  height: 60%;
  width: 35%;
  border-radius: 50px;
  margin-top: 30px;
}

.priceContainer > button span {
  color: white;

  font-weight: var(--fw700) !important;
  font-size: var(--f16);
  line-height: 24px;
  text-wrap: nowrap;
}

.priceContainer > button:disabled {
  border: 1px solid var(--gray-light-300);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  background-color: var(--white);
  height: 42px;
  display: flex;
  padding-right: 1.25rem;
  align-items: center;
  width: 100%;
  padding-inline: 24px;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.5s all;
  /* box-shadow: 0px 3px 2px 0px #ffffff3d inset; */
}

.priceContainer > button:disabled span {
  color: var(--gray-light-300);
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: 24px;
  font-family: var(--font-satoshi);
  text-wrap: nowrap;
}

.selectEmail {
  margin-left: 50px;
  width: 262px;
}

.searchInput {
  padding: 8px 11px;
}

.searchInputDropdown {
  padding: 8px 11px;
}

.searchInputDropdownContainor {
  padding: 8px;
}

.imgGoogle {
  width: 16px;
  position: absolute;
  padding-left: 10px;
  padding-top: 3px;
}

.selectMailtext {
  font-weight: var(--fw400);
  font-size: var(--f14);
  color: var(--black-color);
}

.tablePagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px var(--f20);
  width: -webkit-fill-available;
  background-color: white;
  height: 60px;
  border: 1px solid #d1d1d1;
  border-radius: 12px;
  margin-top: 18px;
  position: relative;
}

.tablePagenation .table_counts {
  right: 24px;
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.tablePagenation :global(.ant-pagination) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tablePagenation :global(.ant-pagination-options) {
  display: none;
}

.tablePagenation
  :global(.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link),
.tablePagenation
  :global(
    .ant-pagination .ant-pagination-next:hover .ant-pagination-item-link
  ) {
  background-color: transparent;
}

.tablePagenation
  :global(
    .ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover
  ) {
  background-color: var(--email-icone-background) !important;
  border: 1px solid #fdd0ab !important;
}

.tablePagenation
  :global(
    .ant-pagination
      .ant-pagination-item:not(.ant-pagination-item-active):hover
      a
  ) {
  color: var(--primary-color) !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.tablePagenation :global(.ant-pagination .ant-pagination-prev:hover svg),
.tablePagenation :global(.ant-pagination .ant-pagination-next:hover svg) {
  fill: #f75f18;
}

.tablePagenation :global(.ant-pagination .ant-pagination-disabled:hover svg) {
  fill: #98a2b3;
}

.tablePagenation svg {
  fill: #292d32;
}

.tablePagenation :global(.ant-pagination-disabled) svg {
  fill: #98a2b3;
}

.tablePagenation :global(.ant-pagination-prev) {
  margin-right: 24px;
}

.tablePagenation :global(.ant-pagination-next) {
  margin-left: 24px;
}

.tablePagenation :global(.ant-pagination-item) {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 8px;
}

.tablePagenation :global(.ant-pagination li:nth-child(2).ant-pagination-item) {
  margin-inline-start: 8px;
}

.tablePagenation
  :global(.ant-pagination)
  :global(.ant-pagination-item-active)
  a {
  color: var(--primary-color);
  background-color: var(--email-icone-background) !important;
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.tablePagenation :global(.ant-pagination) :global(.ant-pagination-item-active) {
  border-color: var(--email-icone-border);
  border-radius: 50%;
}

.tablePagenation
  .ant-select-focused:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #e8450e !important;
  box-shadow: none !important;
}

.tablePagenation :global(.ant-pagination-item-active) :hover {
  border-color: #e8450e !important;
}

.tablePagenation :global(.ant-pagination-item-active) :hover a {
  color: white !important;
}

.custome_selectOtption_lable {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
}

.emailType_logo {
  height: 34px;
}

.custome_selectOtption_lable svg {
  width: 16px;
  height: 16px;
}
