.choosePlanContaienr {
  padding: var(--f40);
  background: var(--white);
  border-radius: var(--f16);
}

:global(.ant-tabs).choosePlanMainContaienrTabBtn
  :global(.ant-tabs-nav-wrap > .ant-tabs-nav-list) {
  background: var(--white);
  padding: 4px;
  border-radius: 25px;
  border: 1px solid var(--gray-light-200);
}

:global(.ant-tabs).choosePlanMainContaienrTabBtn :global(.ant-tabs-nav-wrap) {
  padding-bottom: 40px;
}

:global(.ant-tabs).choosePlanMainContaienrTabBtn :global(.ant-tabs-tab) {
  padding: 0;
  margin: 0 !important;
}

:global(.ant-tabs).choosePlanMainContaienrTabBtn :global(.ant-tabs-tab-active) {
  background: var(--primary-color);
  color: var(--white) !important;
  border-radius: 100px;
  padding-right: 6px;
  margin: 0;
}

:global(.ant-tabs).choosePlanMainContaienrTabBtn
  :global(.ant-tabs-tab-active)
  .monthBtn
  > p,
:global(.ant-tabs).choosePlanMainContaienrTabBtn
  :global(.ant-tabs-tab-active)
  .yearBtn
  > p {
  color: var(--white);
}

:global(.ant-tabs).choosePlanMainContaienrTabBtn :global(.ant-tabs-tab-active) {
  border: 0 !important;
}

:global(.ant-tabs-top).choosePlanMainContaienrTabBtn
  :global(.ant-tabs-nav::before) {
  border-bottom: none;
}

:global(.ant-tabs).choosePlanMainContaienrTabBtn
  :global(.ant-table-wrapper table) {
  border: 1px solid var(--gray-light-200);
  border-radius: 12px !important;
}

:global(.ant-tabs).choosePlanMainContaienrTabBtn
  :global(.ant-tabs-nav .ant-tabs-ink-bar) {
  display: none;
}

.monthBtn > p {
  margin: 0;
  font-weight: var(--fw600);
  font-size: var(--f12);
  line-height: var(--f18);
  padding: 7px 23px;
  color: var(--primary-black);
}

.yearBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.yearBtn > p {
  margin: 0;
  font-weight: var(--fw600);
  font-size: var(--f12);
  line-height: var(--f18);
  padding: 7px 23px;

  color: var(--black-color);
}

.yearBtn > button {
  margin: 0;
  font-weight: var(--fw700);
  color: #743813;
  font-size: var(--f8);
  line-height: var(--f18);
  border: none;
  border-radius: 60px;
  background-color: #f9e68e;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-top: var(--f40);
  background-color: var(--gray-light-50);
  border: 1px solid var(--gray-light-200);
}

.footer > p {
  padding: 20px 0;
  margin: 0;
  font-weight: var(--fw500);
  font-size: var(--f16);
  line-height: 24px;
  font-family: var(--font-satoshi);
  color: #1f2937;
}

.footer > p > span {
  padding: 20px 0;
  font-weight: var(--fw500);
  font-size: var(--f16);
  line-height: 24px;
  font-family: var(--font-satoshi);
  color: var(--primary-color);
}

.headerContaienr {
  display: flex;
  margin-left: 25px;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
}

.headerContaienr > div {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--email-icone-border);
  background-color: #fff9f5;
  border-radius: 50%;
}

.headerContaienr > p {
  font-weight: var(--fw700);
  font-family: var(--font-satoshi);
  font-size: var(--f20);
  line-height: 30px;
  margin: 0;
  color: var(--primary-black);
}

.priceContainer {
  padding: 32px 24px;
  /* background: var(--white); */
  display: flex;
  flex-direction: column;
  text-align: left;
  row-gap: 32px;
}

.priceContainer > div > p {
  margin: 0;
  font-weight: var(--fw400);
  font-size: var(--f16);
  line-height: 24px;
  color: var(--gray-light-700);
}

.priceContainer > div > h2 {
  margin: 0;
  font-weight: var(--fw700);
  font-size: var(--f48);
  line-height: 60px;
  font-family: var(--font-epilogue);
  color: #101828;
}

:global(.ant-slider).slider :global(.ant-slider-rail) {
  background-color: #fff5ed;
}

:global(.ant-slider-horizontal).slider {
  display: flex;
  align-items: center;
  height: 24px;
}

:global(.ant-slider).slider :global(.ant-slider-handle::after) {
  height: 24px;
  width: 24px;
}

:global(.ant-slider).slider :global(.ant-slider-track) {
  background-color: #fff5ed;
}

:global(.ant-slider).slider :global(.ant-slider-handle::after) {
  box-shadow: 0 0 0 2px var(--primary-color);
}

:global(.ant-slider).slider :global(.ant-slider-rail),
:global(.ant-slider).slider :global(.ant-slider-track),
:global(.ant-slider).slider :global(.ant-slider-step) {
  height: 8px;
}

:global(.ant-slider).slider :global(.ant-slider-handle::before) {
  height: var(--f28);
  width: var(--f28);
}
.priceContainer > button {
  border: 1px solid #c35a1f;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  background-color: #f75f18;
  height: 42px;
  display: flex;
  padding-right: 1.25rem;
  align-items: center;
  width: 100%;
  padding-inline: 24px;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.5s all;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.priceContainer > button:hover {
  background-color: #e8450e;
}

.priceContainer > button span {
  color: white;

  font-weight: var(--fw700) !important;
  font-size: var(--f16);
  line-height: 24px;
  text-wrap: nowrap;
}

.priceContainer > button:disabled {
  border: 1px solid #d0d5dd;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  background-color: var(--white);
  height: 42px;
  display: flex;
  padding-right: 1.25rem;
  align-items: center;
  width: 100%;
  padding-inline: 24px;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.5s all;
  /* box-shadow: 0px 3px 2px 0px #ffffff3d inset; */
}

.priceContainer > button:disabled span {
  color: #d0d5dd;
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: 24px;
  font-family: var(--font-satoshi);
  text-wrap: nowrap;
}
/* .priceContainer>button {
    border: 2px solid #c35a1f;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    background-color: #f75f18;
    height: 42px;
    display: flex;
    padding-right: 1.25rem;
    align-items: center;
    width: 100%;
    padding-inline: 24px;
    justify-content: center;
    border-radius: 0.5rem;
    transition: 0.5s all;
    box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.priceContainer>button:hover {
    background-color: #e8450e;
}

.priceContainer>button span {
    color: white;
    font-weight: 600;
    font-size: 16px;
} */
.growthSubscriptionBtn > button {
  border: 1px solid #d0d5dd !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  background-color: var(--white) !important;
  height: 42px;
  display: flex;
  padding-right: 1.25rem;
  align-items: center;
  width: 100%;
  padding-inline: 24px;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.5s all;
  /* box-shadow: 0px 3px 2px 0px #ffffff3d inset; */
}

.growthSubscriptionBtn > button:hover {
  border: 1px solid #d0d5dd !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  background-color: var(--white) !important;
  height: 42px;
  display: flex;
  padding-right: 1.25rem;
  align-items: center;
  width: 100%;
  padding-inline: 24px;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.5s all;
  /* box-shadow: 0px 3px 2px 0px #ffffff3d inset; */
}

.growthSubscriptionBtn > button span {
  color: var(--gray-light-700) !important;
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: 24px;
  font-family: var(--font-satoshi);
  text-wrap: nowrap;
}

:global(.ant-table-wrapper).pricePlanTable
  :global(.ant-table-container)
  :global(.ant-table-content)
  table
  > colgroup
  > col:nth-last-child(3) {
  background-color: #fff5ed !important;
}

.popularBtn {
  position: absolute;
  top: -9px;
  text-wrap: nowrap;
  background-color: var(--primary-color);
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 9px;
}

.popularBtn > span {
  color: var(--white);
  background-color: var(--primary-color);
  font-weight: var(--fw700);
  font-size: var(--f10);
  line-height: 12px;
}

@media (1024px <=width <=1200px) {
  .choosePlanContaienr {
    padding: 20px;
  }
}

.sliderHidden {
  visibility: hidden;
}

.btnMargin {
  margin-top: -14px;
}

.enterpriseBtn {
  margin-top: inherit;
}
.spinLoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
:global(.ant-table-wrapper).pricePlanTable :global(.ant-table-tbody > tr > td) {
  font-weight: 500;
}
:global(.ant-spin).spinLoader :global(.ant-spin-dot-item) {
  background-color: #e8450e;
}
