.mainCardContainer {
  margin-top: 20px;
  box-shadow: var(--shadow) !important;
}

.cardTitleUsage {
  font-size: var(--f20);
  font-weight: var(--fw700);
  padding-left: 22px;
}

.cardUpgradeButton {
  display: flex;
  height: 42px;
  width: 200px;
  margin-right: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-border-color);
  font-size: var(--f16);
  font-weight: var(--fw600);
  color: var(--white);
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.cardUpgradeButton:hover {
  background-color: #e8450e;
}

.mainCreditContainer {
  display: flex;
  width: 100%;
  padding-left: var(--f24);
  padding-right: var(--f24);
  padding-bottom: var(--f24);
  flex-direction: column;
}

.mainCreditContainer div {
  font-size: var(--f32);
  font-weight: var(--fw700);
  margin-top: 10px;
  color: var(--green-progress-100);
}

.mainCreditContainer span {
  font-size: var(--f16);
  font-weight: var(--fw700);
  margin-top: 10px;
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

@media screen and (max-width: 1250px) {
  .cardTitleUsage {
    font-size: var(--f18);
  }

  .mainCreditContainer div {
    margin-top: 0px !important;
  }
  .cardUpgradeButton{
    width: 150px;
  }
}