.emailSetupMainContaienr,
.inboxPlacementContaienr {
  width: 100%;
  background: var(--white);
  padding: var(--f24);
  border-radius: var(--f12);
  box-shadow: var(--shadow);
}

.emailSetupMainContaienr > h3,
.inboxPlacementContaienr > h3 {
  font-size: var(--f20);
  font-weight: var(--fw700);
  color: var(--primary-black) !important;
  line-height: var(--f24) !important;
  font-family: var(--font-epilogue);
  margin: var(--f0);
}

.progressContainer,
.circleProgressText {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px var(--f0);
}

.circleProgressText > span,
.inboxCircleProgressText > span {
  font-weight: var(--fw700) !important;
  font-size: var(--f24) !important;
  color: var(--primary-black) !important;
  line-height: var(--f38);
  font-family: var(--font-satoshi);
}

.circleProgressText > p {
  display: flex;
  border: 1px solid var(--light-green-700);
  width: fit-content;
  padding: 0.25rem var(--f8);
  background-color: var(--light-green-500);
  border-radius: 9999px;
  font-size: var(--f14);
  align-items: center;
  justify-content: center;
  gap: var(--f8);
  margin: var(--f0);
  padding-top: var(--f2);
  margin-top: var(--f2);
}

.circleProgressText > p > span .inboxCircleProgressText > p > span {
  font-weight: var(--fw700) !important;
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  line-height: var(--f24);
  color: #079455;
}

.progressContainer > button {
  border: 1px solid var(--light-green-700);
  padding-top: var(--f8);
  padding-bottom: var(--f8);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  gap: var(--f8);
  border-radius: var(--f8);
  margin-top: 24px !important;
  background-color: #ecfdf3;
  display: flex;
  align-items: center;
  font-family: var(--font-satoshi);
}

.progressContainer > button > span {
  font-family: var(--font-satoshi);
}

.inboxplacementbtn {
  /* border: 1px solid var(--light-red-700) !important; */
  padding-top: var(--f8);
  padding-bottom: var(--f8);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  gap: var(--f8);
  border-radius: var(--f8);
  margin-top: 25px !important;
  /* background-color: var(--light-red-500) !important; */
  display: flex;
  align-items: center;
}

.checkIcons {
  /* color: #47cd89; */
  font-size: var(--f20);
}

.warningIcons {
  /* color: #f97066; */
  font-size: var(--f20);
}

.progressContainer > button > span {
  font-weight: var(--fw700) !;

  font-size: var(--f16);

  line-height: 1.5;
}

.InboxText {
  color: #f97066 !important;
  border: 1px solid var(--light-red-700) !important;
  background-color: var(--light-red-500) !important;
}

.DomainSetupDetailsLabel {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: var(--f16);
}

.DomainSetupDetailsLabel > h3 {
  font-weight: bold;

  font-size: var(--f20);
  margin: var(--f0);

  line-height: 1.5;
}

/* .DomainSetupDetailsLabel > button {
  padding-left: 0.75rem;

  padding-right: 0.75rem;

  padding-top: 0.25rem;

  padding-bottom: 0.25rem;

  color: #17b26a;

  gap: 0.75rem;

  display: flex;

  align-items: center;

  justify-content: space-between;

  border-radius: 9999px;

  border: 1px solid #abefc6;

  background-color: #ecfdf3;
} */

.domainSetupChildrenContainer {
  padding: var(--f16);
  border: 1px solid #abefc6;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: var(--f12);
  background-color: #ecfdf3;
}
.domainSetupChildrenContainerWarning {
  padding: var(--f16);
  border: 1px solid #efe0a6;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: var(--f12);
  background-color: #fff7de;
}

.domainSetupChildrenRedContainer {
  padding: var(--f16);
  border: 1px solid #efabd3 !important;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: var(--f12);
  background-color: #ffe1e1 !important;
}

.domainSetupChildrenRedContainer > .checkIcons {
  color: #e31f1f !important;
  font-size: var(--f20);
}

.domainSetupChildrenRedContainer > .textContainer {
  font-weight: normal;
  font-size: var(--f16);
  line-height: var(--f20);
  color: #71717a;
  /* padding-top: var(--f16); */
}

.domainSetupChildrenRedContainer > .textContainer > h5 {
  font-weight: bold;
  font-size: var(--f16);
  line-height: 1.5;
  margin: var(--f0);
  color: #000000;
}

.domainSetupChildrenRedContainer > .textContainer > p {
  font-weight: normal;
  font-size: var(--f14);
  line-height: 1.5;
  color: #475467;
}
.rightBtnContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0 20px;
  border-radius: var(--f12);
  /* background-color: #ffe1e1 !important; */
}
.rightBtnContainer > p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #101828;
  cursor: pointer;
}
.rightBtnContainer > p > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fw700);
  font-size: var(--f14);
  line-height: 24px;
}
.headerTitleSubject > p {
  font-family: var(--font-epilogue);
  font-size: var(--f16);
  font-weight: var(--fw400);
  line-height: 24px;
  padding-left: 2px;
}
.headerTitleSubject > p > span {
  font-family: var(--font-epilogue);
  font-size: var(--f16);
  font-weight: var(--fw500);
  line-height: 24px;
}

.domainSetupChildrenRedContainer > .textContainer > p > span {
  font-weight: bold;
  font-size: var(--f14);
  line-height: 1.5;
}
.noFileAndLinks {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.noFileAndLinks > h5 {
  font-size: 18px;
}
.noFileAndLinks > h5,
.noFileAndLinks > p {
  margin: 0;
}

.domainSetupChildrenContainer > .checkIcons {
  color: #059669;
  font-size: var(--f20);
}
.paragraph_of_line {
  margin: 0;
  font-size: 14px;
  padding: 4px 0;
}
.checkIconsBtn {
  color: #f3b302;
  font-size: var(--f20);
}
.domainSetupChildrenContainerWarning > .checkIcons {
  color: #f8d16a;
  font-size: var(--f20);
}

.domainSetupChildrenContainer > .textContainer,
.domainSetupChildrenContainerWarning > .textContainer {
  font-weight: normal;
  font-size: var(--f16);
  line-height: var(--f20);
  color: #71717a;
  /* padding-top: var(--f16); */
}

.domainSetupChildrenContainer > .textContainer > h5,
.domainSetupChildrenContainerWarning > .textContainer > h5 {
  font-weight: bold;
  font-size: var(--f16);
  line-height: 1.5;
  margin: var(--f0);
  color: #000000;
}

.domainSetupChildrenContainer > .textContainer > p,
.domainSetupChildrenContainerWarning > .textContainer > p {
  font-weight: normal;
  font-size: var(--f14);
  line-height: 1.5;
  padding-top: var(--f4);
  margin: var(--f0);
  color: #475467;
}

.domainSetupChildrenContainer > .textContainer > p > span,
.domainSetupChildrenContainerWarning > .textContainer > p > span {
  font-weight: bold;
  font-size: var(--f14);
  line-height: 1.5;
}

.domainSetupChildrenContainer {
  padding: var(--f16);
  border: 1px solid #abefc6;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: var(--f12);
  background-color: #ecfdf3;
}

.domainSetupChildrenContainer > .checkIcons {
  color: #059669;
  font-size: var(--f20);
}

.domainSetupChildrenContainer > .textContainer {
  font-weight: normal;
  font-size: var(--f16);
  line-height: var(--f20);
  color: #71717a;
  /* padding-top: var(--f16); */
}

.domainSetupChildrenContainer > .textContainer > h5 {
  font-weight: bold;
  font-size: var(--f16);
  line-height: 1.5;
  margin: var(--f0);
  color: #000000;
}

.domainSetupChildrenContainer > .textContainer > p {
  font-weight: normal;
  font-size: var(--f14);
  line-height: 1.5;
  color: #475467;
}

.domainSetupChildrenContainer > .textContainer > p > span {
  font-weight: bold;
  font-size: var(--f14);
  line-height: 1.5;
}

.detailsText {
  font-weight: var(--fw400);
  font-size: var(--f14);
  line-height: 1.5;
  color: #475467;
  padding-top: var(--f24);
  padding-bottom: var(--f8);
}

.detailsDescriptionContainer {
  padding: var(--f16);

  background-color: #f2f4f7;

  border-radius: var(--f8);
}

.detailsDescriptionContainer > p {
  font-weight: normal;

  font-size: var(--f14);

  line-height: 1.5;

  color: #475467;
}

.emailContactsContainer {
  padding: var(--f24);

  border: 1px solid #eaecf0;

  border-radius: var(--f8);

  background-color: #ffffff;

  margin-bottom: var(--f16);
}

.emailContactsContainer > p {
  font-weight: var(--fw500);

  font-size: var(--f14);

  line-height: var(--f20);

  color: #101828;
}

.emailContactsContainer > h2 {
  font-weight: bold;

  font-size: var(--f19);

  line-height: var(--f38);

  margin-top: var(--f8);
}

.inboxPlacementMainContainer {
  padding-inline: var(--f12);
}

.headerContainer {
  padding: var(--f16);

  border: 1px solid #abefc6;

  display: flex;

  align-items: center;

  gap: var(--f12);

  border-radius: var(--f12);

  background-color: var(--light-green-500);
}

.headerContainer > .checkCircleIcons {
  color: #059669;

  font-size: var(--f24);
}

.emailSetupMainContaienr,
.inboxPlacementContaienr {
  width: 100%;
  background: var(--white);
  padding: var(--f24);
  border-radius: var(--f12);
  box-shadow: var(--shadow);
  padding-bottom: var(--f0);
}

.emailSetupMainContaienr > h3,
.inboxPlacementContaienr > h3 {
  font-size: var(--f20);
  font-weight: var(--fw700);
  color: var(--black-color);
  line-height: var(--f28);
  font-family: var(--font-epilogue);
  /* font-family: 'Epilogue'; */
}

.progressContainer,
.circleProgressText {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px var(--f0);
}

.circleProgressText > span,
.inboxCircleProgressText > span {
  font-weight: var(--fw700);
  font-size: var(--f20);
  color: var(--black-color);
  line-height: var(--f38);
}

.circleProgressText > p {
  display: flex;
  border: 1px solid var(--light-green-700);
  width: fit-content;
  padding: 0.25rem var(--f8);
  background-color: var(--light-green-500);
  border-radius: 9999px;
  font-size: var(--f14);
  align-items: center;
  justify-content: center;
  gap: var(--f8);
}

.circleProgressText > p > span,
.inboxCircleProgressText > p > span {
  font-weight: var(--fw700);
  font-size: var(--f14);
  line-height: var(--f24);
  font-family: var(--font-satoshi);
  /* color: #079455; */
}

.progressContainer > button {
  border: 1px solid var(--light-green-700);
  padding-top: var(--f8);
  padding-bottom: var(--f8);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  gap: var(--f8);
  border-radius: var(--f8);
  margin-top: var(--f36);
  background-color: var(--light-green-500);
  display: flex;
  align-items: center;
}

.inboxplacementbtn {
  /* border: 1px solid var(--light-red-700) !important; */
  padding-top: var(--f8);
  padding-bottom: var(--f8);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  gap: var(--f8);
  border-radius: var(--f8);
  margin-top: var(--f36);
  /* background-color: var(--light-red-500) !important; */
  display: flex;
  align-items: center;
}

.checkIcons {
  /* color: #47cd89; */
  font-size: var(--f20);
}

.warningIcons {
  color: #f97066;
  font-size: var(--f20);
}

.progressContainer > button > span {
  font-weight: var(--fw700);

  font-size: var(--f16);

  line-height: 1.5;
  color: var(--primary-black);
}

/* 
.InboxText {
  color: #f97066 !important;
  border: 1px solid var(--light-red-700) !important;
  background-color: var(--light-red-500) !important;
}

.InboxText>span {
  color: #f97066 !important;
} */

.excellentText {
  border: 1px solid #47cd89 !important;
  background-color: rgba(71, 205, 138, 0.11) !important;
}

.veryGoodText {
  border: 1px solid rgb(135 196 26) !important;
  background-color: rgba(134, 196, 26, 0.11) !important;
}

.goodText {
  border: 1px solid #dbe01d !important;
  background-color: rgba(219, 226, 29, 0.11) !important;
}

.AverageText {
  border: 1px solid #e0aa0b !important;
  background-color: rgba(245, 195, 46, 0.11) !important;
}

.poorText {
  border: 1px solid #ff912b !important;
  background-color: rgba(255, 146, 43, 0.11) !important;
}

.terribleText {
  border: 1px solid #f97066 !important;
  background-color: hsla(4, 92%, 69%, 0.11) !important;
}

.excellentText > span {
  color: #47cd89;
}

.veryGoodText > span {
  color: rgb(135 196 26);
}

.goodText > span {
  color: #bfc402;
}

.AverageText > span {
  color: #e0aa0b;
}

.poorText > span {
  color: #ff912b;
}

.terribleText > span {
  color: #f97066;
}

.DomainSetupDetailsLabel {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: var(--f16);
}

.DomainSetupDetailsLabel > h3 {
  font-weight: var(--fw700);
  font-family: var(--font-epilogue);
  font-size: var(--f20);
  line-height: 1.5;
}

/* .DomainSetupDetailsLabel > button {
  padding-left: 0.75rem;
  font-weight: var(--fw500);
  font-size: 12px;
  padding-right: 0.75rem;

  padding-top: 0.25rem;

  padding-bottom: 0.25rem;

  color: #17b26a;

  gap: 0.75rem;

  display: flex;

  align-items: center;

  justify-content: space-between;

  border-radius: 9999px;

  border: 1px solid #abefc6;

  background-color: #ecfdf3;
} */

.detailsText {
  font-weight: var(--fw400);
  font-size: var(--f14);
  line-height: 1.5;
  color: var(--primary-black);
  padding-top: var(--f24);
  margin: var(--f0);
  padding-bottom: var(--f8);
}

.detailsDescriptionContainer {
  padding: var(--f16);

  background-color: #f2f4f7;

  border-radius: var(--f8);
}

.detailsDescriptionContainer > p {
  font-weight: normal;

  font-size: var(--f14);
  margin: var(--f0);
  line-height: 1.5;

  color: #475467;
}

.emailContactsContainer {
  padding: var(--f24);

  border: 1px solid #eaecf0;

  border-radius: var(--f8);

  background-color: #ffffff;

  margin-bottom: var(--f16);
}

.emailContactsContainer > p {
  font-weight: var(--fw500);

  font-size: var(--f14);
  margin: var(--f0);
  line-height: var(--f20);

  color: var(--primary-black);
}

.emailContactsContainer > h2 {
  font-weight: var(--fw700);

  font-size: var(--f19);

  line-height: var(--f38);

  margin-top: var(--f8);
}

.inboxPlacementMainContainer {
  padding-inline: var(--f12);
}

.headerContainer {
  padding: var(--f16);

  border: 1px solid #abefc6;

  display: flex;

  align-items: center;

  gap: var(--f12);

  border-radius: var(--f12);

  background-color: var(--light-green-500);
}

.headerContainer > .checkCircleIcons {
  color: #059669;

  font-size: var(--f24);
}

.headerContainer > div {
  font-weight: normal;

  font-size: var(--f16);
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  line-height: var(--f20);

  color: #6b7280;
}

.headerContainer > div > h5 {
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: 1.5;
  margin: var(--f0);
  color: #000;
}

.headerContainer > div > p {
  font-weight: var(--fw400);
  font-size: var(--f14);
  line-height: 1.5;
  margin: var(--f0);
  color: #475467;
}

.boxContainer {
  padding-top: var(--f12);
}

.boxDetailsContainer {
  padding: var(--f24);

  width: 100%;
  background-color: #fff;
  border-radius: var(--f8);

  border: 1px solid #eaecf0;
}

.boxDetailsContainer > p {
  font-weight: var(--fw500);

  font-size: var(--f14);
  line-height: var(--f20);
  margin: var(--f0);
  color: #475467;
}

.boxDetailsContainer > h3 {
  font-weight: var(--fw700);
  font-size: var(--f30);

  color: #6941c6;
  margin: var(--f0);

  padding-top: var(--f8);
}

.providerContainer {
  padding: var(--f16);

  border: 1px solid #eaecf0;

  border-radius: var(--f8);

  margin-top: 0.75rem;
}

.providerContainer > .providerHeader,
.collpseItemContainer {
  display: flex;
  align-items: center;
}

.providerHeader {
  padding-bottom: 20px !important;
}

.providerContainer > .providerHeader > .headerLogo {
  padding: var(--f16);

  border: 1px solid #eaecf0;

  background-color: #fff;

  border-radius: var(--f12);
}

.providerHeaderText {
  padding-inline: var(--f20);
}

.providerHeaderText > h3 {
  font-weight: var(--fw700);
  padding-bottom: var(--f8);
  font-family: var(--font-epilogue);
  font-size: var(--f20);
  margin: var(--f0);
  line-height: 1.5;
  font-weight: var(--fw500);
  color: #000;
}

.providerHeaderText > button {
  margin-top: var(--f4);

  padding-left: var(--f12);

  padding-right: var(--f12);

  padding-top: var(--f4);

  padding-bottom: var(--f4);

  gap: var(--f12);

  display: flex;

  align-items: center;

  justify-content: center;

  border-radius: 9999px;
}

.providerHeaderText .green,
.emailBoxContaienr .green {
  color: #17b26a !important;
  border: 1px solid #abefc6 !important;
  background-color: #ecfdf3 !important;
}

.providerHeaderText .red,
.emailBoxContaienr .red {
  border: 1px solid #e31f1f !important;
  background-color: #ffe1e1 !important;
  color: #e31f1f !important;
}

.emailBoxContaienr {
  padding: var(--f16);

  border: 1px solid #eaecf0;

  border-radius: var(--f8);

  background-color: #fff;
}

.emailBoxContaienr > a {
  font-weight: var(--fw500);

  font-size: var(--f16);

  line-height: 1.5;

  color: var(--primary-black);
}

@media (400px <=width <=1300px) {
  .emailBoxContaienr > a {
    font-size: var(--f12);
  }
}

.emailBoxContaienr > button {
  margin-top: var(--f4);
  font-weight: var(--fw500);

  padding-left: var(--f12);

  padding-right: var(--f12);

  padding-top: var(--f4);

  padding-bottom: var(--f4);

  gap: var(--f12);

  display: flex;

  align-items: center;

  justify-content: center;

  border-radius: 9999px;
}

.collepseMainContainer {
  padding-top: var(--f24);
}

.collepseContainer {
  background-color: #fff;

  width: 100%;

  padding: var(--f24);

  border-radius: var(--f12);

  box-shadow: var(--shadow);
  margin: 0;
}

.collepseContainer > h3 {
  font-size: var(--f20);
  font-family: var(--font-epilogue);
  color: var(--primary-black);
  line-height: var(--f24);
  margin: 0;
  font-weight: bold;
}

.collepseContainer > div {
  margin-top: var(--f24);
}

.collpseItemDetails {
  padding: var(--f16);

  border: 1px solid #eaecf0;

  background-color: #fff;

  border-radius: var(--f12);
}

.itemDetailsContaienr {
  padding-inline: var(--f20);
}

.itemDetailsContaienr > h3 {
  font-weight: var(--fw700);

  padding-bottom: var(--f12);

  font-size: var(--f20);
  margin: var(--f0);

  line-height: var(--f24);
  font-family: var(--font-epilogue);

  color: var(--primary-black);
}

.domainBtnContainer {
  display: flex;
  gap: 5px;
}

.itemDetailsInboxContaienr {
  padding-inline: var(--f20);
}

.itemDetailsInboxContaienr > h3 {
  font-weight: var(--fw700);

  padding-bottom: var(--f12);
  font-family: var(--font-epilogue);
  font-size: var(--f20);
  margin: var(--f0);

  line-height: 1.5;

  color: var(--primary-black);
}

.inboxBtn {
  border: 1px solid #fecdca !important;
  color: #f97066 !important;
  background-color: var(--light-red-500) !important;
}

.userInfo {
  padding-bottom: 32px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 2px;
}

.username {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
}

.username > h4 {
  margin: 0;
  text-wrap: nowrap;
  font-weight: var(--fw700);
  font-size: var(--f20);
  line-height: var(--f20);
  font-family: var(--font-epilogue);
}

.EmailProviderIcons {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 11px;
}

.userMain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.userMain > a {
  font-weight: var(--fw400);
  font-size: var(--f14);
  line-height: var(--f18);
  color: #344054;
}

.inProgressTezxt {
  height: 22px;
  padding: 2px 8px;
  gap: 0px;
  border-radius: 16px;
  border: 1px solid #eaecf0;
  background: #f9fafb;
}

.inProgressTezxt p {
  font-size: var(--f12);
  font-weight: var(--fw500);
  line-height: var(--f18);
  text-align: center;
  color: #344054;
  margin: 0;
}

.inProgressTestButton {
  width: 197px;
  height: 33px;
  color: #b6b6b6 !important;
  border: 1px solid #eaecf0 !important;
  background: #f9fafb !important;
}

/* .inProgressTestButton span {
  height: 5px;
  width: 100%;
  background: #CBD1DC;
} */

.progress_Time {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 4px;
}

.progress_TimeInfo p {
  font-weight: var(--fw500);
  margin: 0;
}

.inProgressLine:global(
    .ant-skeleton.ant-skeleton-element .ant-skeleton-button-sm
  ),
.inProgressLine:global(.ant-skeleton.ant-skeleton-element) {
  height: 5px !important;
}

.lableBtnContainer,
.blacklistBtn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.DomainIpDetailsLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blacklistBtn {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--primary-color);
}

.blacklistedDataList {
  padding: 16px;
  margin: 30px 0 10px 0;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
}

.blacklistedDataList p {
  margin: 0;
}
.titleEmailContent {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.315);
}
.titleEmailContent > h3 {
  margin: 0;
  padding-bottom: 5px;
}
.titleEmailContent > span {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
.publicDeliverabilityTestMainContainer {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 104px);
  padding: 32px;
  margin: 0 32px;
}
.publicDeliverabilityTestMainContainer .collepseContainer {
  margin-bottom: 50px;
}
.speedMeterContainer > div > div > svg {
  height: 100px !important;
  width: 100px !important;
}
