.activeModal .ant-modal-close,
.activeModal .ant-modal-close:hover {
  width: 24px !important;
  height: 24px !important;
  margin-top: 15px;
  margin-right: var(--f6);
  color: var(--primary-black, #000000);
  background-color: var(--white);
}
.activeModal .ant-modal-content {
  height: auto !important;
  background: var(--white, #ffffff);
  border-radius: var(--f16);
}
:global(.ant-modal).activeModal
  :global(.ant-modal-content)
  :global(.ant-modal-header)
  :global(.ant-modal-title) {
  font-size: var(--f20) !important;
  font-weight: var(--fw700);
  font-family: var(--font-epilogue);
  color: var(--gray-light-700);
}
:global(.ant-modal).activeModal
  :global(.ant-modal-content)
  :global(.ant-modal-body)
  :global(.ant-checkbox-wrapper)
  :global(.ant-checkbox-checked)
  :global(.ant-checkbox-inner) {
  background-color: #e8450e;
  border-color: #e8450e;
}
.activeModal .ant-modal-content {
  padding: 0px !important;
}

.activeModalContent {
  padding: 15px 15px 15px 15px;
  border: solid 1px #d0d5dd;
  background-color: #fcfcfd;
  border-radius: var(--f8);
  margin-top: var(--f16);
}

.active {
  background-color: #fff5ed;
  border: solid 1px var(--primary-color);
}
.active2 {
  background-color: #fff5ed;
  border: solid 1px var(--primary-color);
}
.active3 {
  background-color: #fff5ed;
  border: solid 1px var(--primary-color);
}
.active4 {
  background-color: #fff5ed;
  border: solid 1px var(--primary-color);
}
.activeModalContent:hover {
  border: solid 1px var(--primary-color);
}
.activeModalContent div {
  font-size: var(--f14);
  font-weight: var(--fw700);
  color: #1f2937;
}
.activeModalContent1 div {
  font-size: var(--f14);
  font-weight: var(--fw700);
  color: #1f2937;
}
.radio {
  margin-top: var(--f20);
}
.radio span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f12);
  line-height: var(--f20);
  color: #4b5563;
}

.ActiveModalFooter {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--f10);
  width: 100%;
  height: 80px;
  align-items: center;
  border-top: 2px solid #f3f4f6;
  margin-top: 15px;
  margin-bottom: -10px;
}

.ActiveModalFooter button {
  width: 104px;
  height: var(--f40);
  padding: 10px var(--f10);
  gap: var(--f8);
  border-radius: var(--f12);
  border: 1px;
}

.ActiveModalFooter button:nth-child(2) {
  background: var(--white, #ffffff);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  font-size: var(--f14);
  font-weight: var(--fw700);
  color: var(--primary-black);
}

.ActiveModalFooter button:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--f8);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-border-color);
  font-size: var(--f14);
  line-height: 20px;
  font-weight: var(--fw700);
  transition: 0.5s all;
  color: var(--white);
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}
.ActiveModalFooter button:nth-child(1):hover {
  background-color: #e8450e;
}
.DomainSetupDetailsLabel {
  display: flex;
  margin-right: 6rem;
  align-items: center;
  max-width: 190px;
  justify-content: space-between;
}

.DomainSetupDetailsLabel > button {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: var(--primary-color);
  font-size: var(--f12);
  gap: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 9999px;
  border: 1px solid #fdd0ab;
  background-color: #fff5ed;
}
