.ant-card .ant-card-body {
  padding: var(--f0) !important;
  padding-top: var(--f24) !important;
  border-radius: var(--f16) !important;
  overflow: hidden;
}

.templateListTable {
  width: 100%;
}

.templateListTable table {
  margin-top: var(--f24);
}

.templateListTable .ant-card {
  border-radius: var(--f16);
}

.templateListTable .card-main-row {
  padding-left: var(--f34);
  padding-right: var(--f34);
}

.templateListTable .card-main-row .ant-input-affix-wrapper {
  width: 320px;
  height: var(--f40);
}

.mainContainer {
  background-color: white;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.mainContainer div {
  justify-content: center;
  max-width: 630px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mainContainer div img {
  text-align: center;
  width: var(--f180);
  height: var(--f180);
}

.mainContainer div h2 {
  color: black;
  font-weight: var(--fw700);
  font-size: var(--f22);
  line-height: var(--f22);
  margin-top: var(--f40);
  padding-top: var(--f0);
  margin-bottom: var(--f0);
  font-family: var(--font-epilogue);
}

.mainContainer div p {
  color: rgba(102, 112, 133, 1);
  font-weight: var(--fw500);
  font-size: var(--f16);
  text-align: center;
  line-height: var(--f20);
  padding-top: var(--f16);
  margin-bottom: var(--f0);
  margin-top: var(--f0);
}

.mainContainer div button {
  align-items: center;
  border: 1px solid #c35a1f;
  height: 44px;
  background: #f75f18;
  justify-content: center;
  border-radius: var(--f8);
  display: flex;
  transition: 0.5s all;
  padding-top: var(--f16);
  padding-bottom: var(--f16);
  transition: 0.5s all;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  padding-left: var(--f28);
  margin-top: var(--f40);
  padding-right: var(--f28);
}

.mainContainer div button:hover {
  background: #e8450e;
}

.mainContainer div button span {
  color: white;
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: var(--f20);
}

.templateListTable .card-main-row .ant-input-affix-wrapper svg {
  margin-right: var(--f4);
}

.templateListTable .add-button-container {
  text-align: right;
}

.templateListTable .add-button-container button {
  background-color: rgb(247 95 24 / 1);
  align-items: center;
  width: 173px;
  border: 1px solid #c35a1f;
  justify-content: center;
  height: var(--f40);
  border-width: 1px;
  border-radius: var(--f6);
  display: flex;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  transition: 0.5s all;
}

.templateListTable .add-button-container button:hover {
  background-color: #e8450e;
}

.templateListTable .add-button-container button span {
  line-height: var(--f20);
  color: white;
  font-size: var(--f14);
  font-family: var(--font-satoshi);
  font-weight: var(--fw700);
}

.templateListTable tr.selected-row td {
  border-bottom: 1px solid #344054 !important;
  border-top: 1px solid #344054 !important;
}

.templateListTable tr.selected-row td:first-child {
  border-left: 1px solid #344054 !important;
}

.templateListTable tr.selected-row td:last-child {
  border-right: 1px solid #344054 !important;
}

.templateListTable tr:last-child.selected-row td:first-child {
  border-left: 1px solid #344054 !important;
  border-bottom-left-radius: 16px;
}

.templateListTable tr:last-child.selected-row td:last-child {
  border-right: 1px solid #344054 !important;
  border-bottom-right-radius: 16px;
}

.templateContainer input::placeholder {
  color: #98a2b3;
  font-weight: var(--fw500);
  line-height: var(--f20);
}

.templateListTable .ant-table-thead .custom-header {
  height: 54px !important;
  border-top: 1px solid #eaecf0 !important;
  border-bottom: 1px solid #eaecf0 !important;
  background-color: #f2f4f7 !important;
}

.templateListTable .ant-table-thead .custom-header:first-child,
.templateListTable .ant-table-thead .custom-header:last-child {
  border-radius: 0px !important;
}

.templateListTable .ant-table-thead .custom-header span {
  color: #667085;
  font-weight: var(--fw700);
  font-size: var(--f12);
  align-items: center;
  line-height: var(--f18);
  display: flex;
  text-align: left;
  cursor: pointer;
  justify-content: flex-start;
}

.templateListTable .ant-table-thead .custom-header svg {
  margin-left: var(--f8);
}

.columStatusData {
  background-color: #c4fcd4;
  height: var(--f28);
  width: 68px;
  border-radius: var(--f4);
  line-height: var(--f20);
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: var(--fw600);
  font-size: var(--f14);
  color: #027a48;
}

.columData {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  line-height: var(--f20);
  font-weight: var(--fw700);
  text-align: left;
  color: #344054;
}

.columEmailData {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: var(--f10);
}

.ant-table-row {
  cursor: pointer;
}

.columEmailData label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--f38);
  width: var(--f38);
  border-radius: 50px;
  background-color: rgb(24 180 247 / 20%);
  color: #344054;
  font-size: var(--f12);
  font-weight: var(--fw700);
  line-height: var(--f22);
}

.columEmailData div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column-reverse;
}

.columEmailData div span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--f20);
  width: 100%;
}

.columEmailData div span svg {
  height: var(--f16);
  width: var(--f16);
  margin-left: var(--f8);
  cursor: pointer;
}

.faild_email_popover .ant-popover-title {
  margin-bottom: 0px;
}

.faild_email_popover .ant-popover-inner {
  padding: 8px 12px;
}

.faild_email_popover .ant-popover-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  color: #344054;
  min-width: 164px;
}

.success_email_popover svg {
  cursor: auto !important;
}

.columEmailData div span:first-child {
  color: #667085;
  font-size: var(--f12);
  line-height: var(--f18);
  font-weight: var(--fw500);
}

.columEmailData div span:last-child {
  color: #344054;
  font-size: var(--f14);
  line-height: var(--f20);
  font-weight: var(--fw700);
}

.deliverabilityData {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.deliverabilityData .part {
  width: var(--f2);
  background-color: #eaecf0;
  height: var(--f18);
  margin: 0px var(--f8);
}

.deliverabilityData span {
  font-size: var(--f14);
  font-weight: var(--fw700);
  line-height: var(--f20);
}

.no-data-component {
  height: var(--f600);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: var(--f8);
}

.no-data-component .ant-spin .ant-spin-dot-item {
  background-color: #e8450e;
}

.tabelsPagenation {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: var(--f70);
  padding: 0px var(--f20);
}

.tabelsPagenation .ant-pagination-item-active a {
  color: white;
  background-color: #e8450e;
  border: none !important;
  border-radius: var(--f4);
}

.tabelsPagenation .ant-pagination-item-active {
  border-color: #e8450e;
}

.tabelsPagenation
  .ant-select-focused:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #e8450e !important;
  box-shadow: none !important;
}

.tabelsPagenation .ant-pagination-item-active:hover {
  border-color: #e8450e !important;
}

.tabelsPagenation .ant-pagination-item-active:hover a {
  color: white !important;
}

.customDropDown svg {
  cursor: pointer;
}

.customTableDropDown ul {
  border: 1px solid #d0d5dd !important;
  box-shadow: 2px 2px 16px -3px #0000001f !important;
  padding: 8px 0px 8px 0px !important;
}

.customTableDropDown ul li:hover {
  background-color: #f6f6f6 !important;
}

.ant-dropdown-menu-item {
  height: 34px !important;
  display: flex !important;
  align-items: center !important;
  padding: 7px 16px !important;
}

.dropdownText p {
  font-family: var(--font-satoshi);
  font-weight: var(--fw400);
  font-size: var(--f14);
  line-height: var(--f20);
  color: var(--black-color);
}

.ant-notification {
  top: 120px !important;
  right: 8px !important;
  padding: 24px 0px 24px var(--f16);
}

.ant-notification-topRight.ant-notification-stack
  > .ant-notification-notice-wrapper {
  background-color: #f2fbf4;
  border: 1px solid #0491221a;
  border-radius: var(--f4);
}

.notificationText {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw700);
  line-height: var(--f20);
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close {
  width: var(--f48);
  height: var(--f48);
  background-color: #f2fbf4 !important;
  top: var(--f0);
  right: var(--f0);
}

.custom-notification .anticon svg {
  width: var(--f12);
  height: var(--f12);
  color: var(--gray-light-500, #667085);
}

.startBorder {
  width: 5px;
  height: 100%;
  top: var(--f0);
  left: var(--f0);
  border-radius: 4px 0px 0px var(--f4);
  position: absolute;
  background: var(--Tag-Green-500, #049122);
}

.deliverabilityMeterContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.speedometer-needle {
  stroke-width: 3px !important;
}
