.mainCard {
  width: 100%;
  padding: 0 24px 24px;
  box-shadow: var(--shadow) !important;
}

.mainCard button {
  display: flex;
  height: 42px;
  width: 200px;
  align-items: center;
  justify-content: center;
  border-radius: var(--f8);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-border-color);
  font-size: var(--f16);
  font-weight: var(--fw600);
  color: var(--white);
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}
.mainCard button:hover {
  background-color: #e8450e;
}
.mainCard .cardTitleIcon {
  font-size: var(--f20);
  height: var(--f40);
  width: var(--f40);
  padding-top: 5px;
  padding-left: var(--f8);
  border-radius: var(--f8);
  border: 1px solid #eaecf0;
}
.mainCard .cardbtn {
  display: flex;
  gap: var(--f20);
}
.cardUpgradeButton {
  display: flex;
  height: 42px;
  width: 200px;
  margin-right: var(--f22);
  align-items: center;
  justify-content: center;
  border-radius: var(--f8);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-border-color);
  font-size: var(--f16);
  font-weight: var(--fw600);
  color: var(--white);
}
.cardTitle {
  font-size: var(--f18);
  color: var(--gray-light-500);
  font-weight: var(--fw500);
  line-height: 24px;
}
.titleContainer {
  display: flex;
  gap: var(--f20);
}
.titleContainer div {
  font-size: var(--f24);
  margin-top: -5px;
  font-family: var(--font-epilogue);
  font-weight: var(--fw700);
}
.cardTitleUsage {
  font-size: var(--f24);
  font-weight: var(--fw700);
  padding-left: var(--f22);
}
.cardDescription {
  font-size: var(--f18);
  font-weight: var(--fw400);
  color: var(--gray-light-500);
}
.mainContainer {
  display: flex;
  width: 100%;
  padding-left: var(--f22);
  padding-right: var(--f22);
  flex-direction: column;
}
.mainCreditContainer {
  display: flex;
  width: 100%;
  padding-left: var(--f22);
  padding-right: var(--f22);
  flex-direction: column;
}
.mainContainer label {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--f38);
  display: flex;
}
.mainContainer label span {
  font-size: var(--f14);
  font-weight: var(--fw600);
}
.mainCreditContainer span {
  font-size: var(--f32);
  font-weight: var(--fw700);
  margin-top: var(--f10);
  color: var(--green-progress-100);
}
.subLabelNumber {
  font-size: var(--f14);
}
.mainprogress {
  height: var(--f10);
  width: 100%;
  border-radius: 9999px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  background: #eaecf0;
  border: #e5e7e9 1px solid;
}
.progress {
  height: var(--f10);
  width: 240px;
  border-radius: 9999px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  background-color: var(--red-progress-100);
}
.titleContainer span {
  font-size: var(--f14);
  background-color: #ffe1e1;
  padding: 5px;
  height: var(--f28);
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: var(--fw700);
  border-radius: var(--f4);
  color: var(--red-progress-200);
}
.mainCardContainer {
  margin-top: var(--f20);
}
.dateContainer {
  display: flex;
  flex-direction: column;
  margin-top: var(--f20);
}
.dateContainer label {
  font-size: var(--f16);
  font-weight: var(--fw400);
}
.dateContainer span {
  font-size: var(--f18);
  font-weight: var(--fw700);
}
.dateDivider {
  background: #e5e7e9;
  width: 1.5px;
  height: 50px;
  margin-top: var(--f20);
  margin-left: var(--f4);
}
