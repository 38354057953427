.activeModal .ant-modal-close,
.activeModal .ant-modal-close:hover {
  width: 24px !important;
  height: 24px !important;
  margin-top: 15px;
  margin-right: var(--f6);
  color: var(--primary-black, #000000);
  background-color: var(--white);
}

.activeModal .ant-modal-content {
  max-height: 324px;
  background: var(--white, #ffffff);
  border-radius: var(--f16);
}

.activeModal .ant-modal-content {
  padding: 0px !important;
}

.activeModalContent {
  padding: 24px 24px 0px var(--f24);
}

.activeModalHeader {
  /* height: 90px; */
  width: 340px;
}

.activeModalDesc p {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f16);
  color: var(--gray-light-600);
  word-wrap: break-word;
  line-height: 21.6px;
}

.activeModalHeader span {
  height: var(--f56);
  font-family: var(--font-epilogue);
  width: 345px;
  font-weight: var(--fw700);
  font-size: var(--f20);
  color: var(--gray-light-700);
}

.customCheck .ant-checkbox .ant-checkbox-inner,
.customCheck .ant-checkbox .ant-checkbox-inner:hover {
  width: 16px;
  height: 16px;
  border-radius: 50% !important;
  border: 1.5px solid rgba(41, 45, 50, 1) !important;
}

.customCheck .ant-checkbox-checked .ant-checkbox-inner {
  border: 1.5px solid rgba(255, 255, 255, 1);
  /* background-color: white !important; */
  color: red !important;
}
.customCheck span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f14);
  line-height: var(--f20);
  color: rgba(16, 24, 40, 0.5);
}

.ActiveModalFooter {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--f10);
  max-width: 465px;
  height: 80px;
  align-items: center;
  border-top: 2px solid #f3f4f6;
  margin-top: 15px;
  padding-right: var(--f24);
}

.ActiveModalFooter button {
  width: 104px;
  height: var(--f40);
  padding: 10px var(--f10);
  gap: var(--f8);
  border-radius: var(--f12);
  border: 1px;
}

.ActiveModalFooter button:nth-child(2) {
  background: var(--white, #ffffff);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  font-size: var(--f14);
  font-weight: var(--fw700);
  color: var(--primary-black);
  box-shadow: 0px -4px 4px 0px #10182814 inset;
}

.ActiveModalFooter button:nth-child(1) {
  background: #e8450e;
  border: 1px solid var(--primary-border-color, #c35a1f);
  color: var(--white);
  font-weight: var(--fw600);
  font-size: var(--f14);
  box-shadow: 0px -4px 4px 0px #1018281f inset;
}
