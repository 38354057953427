.settingHeader {
  border-bottom: 1px solid #eaecf0;
  font-size: var(--f20);
  font-family: var(--font-epilogue);
  height: 60px;
  width: 100%;
  font-weight: var(--fw700);
  color: var(--gray-light-700);
  margin-bottom: var(--f36);
  padding-top: var(--f8);
  padding-left: var(--f8);
}

.sidebarContainer {
  background-color: white;
  justify-content: flex-start;
  flex-direction: column;
  padding: var(--f25);
  border-radius: 0.75rem;
  display: flex;
  align-items: flex-start;
}

.sidebarContainer div {
  font-size: var(--f16);
  font-weight: var(--fw500);
  margin-top: var(--f8);
  display: flex;
  height: var(--f48);
  width: 100%;
  cursor: pointer;
  align-items: center;
  column-gap: var(--f16);
  border-radius: var(--f6);
  line-height: var(--f28);
  justify-content: flex-start;
  padding-left: var(--f16);
}

.sidebarContainer div.selected {
  border: #fdd0ab 1px solid;
  background-color: #fff5ed;
}

.sidebarContainer div.selected > .icons {
  stroke: #ff4405;
}
.sidebarContainer div.selected > span {
  color: #ff4405;
}

.sidebarContainer div > .icons {
  stroke: #292d32;
}

.mainContainer {
  padding: var(--f25);
  display: flex;
  align-items: flex-start;
  background-color: white;
  flex-direction: column;
  border-radius: 0.75rem;
  justify-content: flex-start;
}

.mainContainer button {
  color: white;
  font-weight: var(--fw700);
  line-height: var(--f24);
  font-size: var(--f16);
  padding-top: var(--f10);
  padding-bottom: var(--f10);
  background-color: rgb(247 95 24 / 1);
  border-radius: var(--f8);
  width: 100%;
  margin-bottom: var(--f8);
  margin-inline-end: var(--f8);
  transition: 0.5s all;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  border: 1px solid #c35a1f;
  margin-top: var(--f30);
}

.mainContainer button:hover {
  background-color: #e8450e;
}

.passwordForm {
  width: 35%;
}

.passwordForm .forgotPasswordDescription {
  justify-content: flex-end;
  display: flex;
}

.passwordForm .forgotPasswordDescription span {
  color: #ff4405;
  font-size: var(--f14);
  cursor: pointer;
  line-height: var(--f24);
  font-weight: var(--fw700);
}

.passwordForm .passwordValidations {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.passwordForm .passwordValidations p {
  font-weight: var(--fw700);
  font-size: var(--f12);
  color: var(--gray-light-700);
  margin-bottom: var(--f8);
}

.passwordForm .passwordValidations svg {
  transition: 0.5s all;
}

.passwordForm .passwordValidations span {
  font-size: var(--f14);
  margin-bottom: 0.25rem;
  font-weight: var(--fw400);
  color: var(--gray-light-700);
  align-items: center;
  display: flex;
  justify-content: flex-start;
  column-gap: var(--f8);
}
