.authContainer {
  background-image: url("../../assets/auth/background.png");
  background-repeat: no-repeat;
  background-size: 100% var(--vh100);
  height: var(--vh100);
  display: flex;
  justify-content: center;
  align-items: center;
}

.authContainer .authComponent {
  width: 26%;
  height: var(--vh72);
  background-color: white;
  border-radius: var(--f16);
  color: black;
  padding: var(--f48) var(--f64);
  box-shadow: rgba(0, 0, 0, 0.24) var(--f0) var(--f3) var(--f8);
}

.authContainer .email_varification_component {
  width: auto;
  padding: var(--f80) var(--f104);
}

@media screen and (max-width: 1700px) {
  .authContainer .authComponent {
    height: auto;
    padding-bottom: var(--f20);
    width: 36%;
    padding-top: var(--f20);
    padding-left: var(--f32);
    padding-right: var(--f32);
  }

  .authContainer .authComponent form .ant-form-item {
    margin-bottom: var(--f12) !important;
  }
}

@media screen and (max-width: 1098px) {
  .authContainer .authComponent {
    height: var(--vh88);
    padding-bottom: var(--f12);
    width: 36%;
    padding-top: var(--f12);
    padding-left: var(--f26);
    overflow: auto;
    padding-right: var(--f26);
  }

  .authContainer .authComponent::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: #fcae7530;
  }

  .authContainer .authComponent::-webkit-scrollbar {
    width: var(--f2);
    border-radius: 50px;
    background-color: #fcae7530;
  }

  .authContainer .authComponent::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #fcae75;
  }

  .authContainer .authComponent form .ant-form-item {
    margin-bottom: var(--f0) !important;
  }
}

@media screen and (max-width: 962px) {
  .authContainer .authComponent {
    padding-bottom: var(--f14);
    padding-top: var(--f14);
  }

  .authContainer .authComponent form .ant-form-item {
    margin-bottom: var(--f0) !important;
  }
}
