.mainCard {
  width: 100%;
  padding: 0 24px 24px;
  box-shadow: var(--shadow);
}

:global(.ant-col) .mainCard :global(.ant-card-body) {
  border-radius: 8px !important;
}
.mainCard button.upgradeButton {
  display: flex;
  height: 42px;
  width: 126px;
  align-items: center;
  justify-content: center;
  border-radius: var(--f8);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-border-color);
  font-size: var(--f14);
  line-height: 20px;
  font-weight: var(--fw700);

  transition: 0.5s all;
  color: var(--white);
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}
.subsciptionContainer{
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: flex;
  flex-grow: 1;
}
.mainCard button.upgradeButton:hover {
  background-color: #e8450e;
}

.cardTitle {
  font-size: var(--f18);
  color: var(--gray-light-500);
  font-weight: var(--fw500);
  line-height: 24px;
}

.titleContainer {
  display: flex;
  gap: var(--f16);
  align-items: center;
}

.titleContainer div {
  font-size: var(--f24);
  font-family: var(--font-epilogue);
  font-weight: var(--fw700);
}

.cardDescription {
  font-size: var(--f18);
  font-weight: var(--fw400);
  color: var(--gray-light-500);
  padding: 16px 0 16px 0;
}

.titleContainer > div {
  font-weight: var(--fw700);
  color: #344054;
  font-family: var(--font-epilogue);
  line-height: 24px;
  margin-top: 3px;
}

.titleContainer span {
  font-size: var(--f14);
  background-color: #d7ffdf;
  padding: 5px;
  height: var(--f28);
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: var(--fw700);
  border-radius: var(--f4);
  color: var(--green-progress-100);
}

.titleContainer label {
  font-size: var(--f14);
  background-color: #ffe1e1;
  padding: 5px;
  height: var(--f28);
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: var(--fw700);
  border-radius: var(--f4);
  color: var(--red-progress-200);
}

.dateWarn {
  font-size: var(--f14);
  background-color: #fcf3c9;
  padding: 5px;
  height: var(--f28);
  display: flex;
  align-items: center;
  margin-top: var(--f10);
  width: fit-content;
  border-radius: var(--f4);
  font-weight: var(--fw700);
  color: var(--yellow-progress-100);
}
