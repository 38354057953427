.detailsComponent {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-bottom: var(--f16);
}

.verifyComponent .title,
.signUpComponent .title,
.resetPasswordComponent .title,
.loginComponent .title,
.forgotPasswordComponent .title,
.emailVarificationComponent .title {
  line-height: var(--f24);
  font-weight: var(--fw700);
  font-size: var(--f24);
  padding-bottom: var(--f0);
  padding-top: var(--f0);
  margin-top: var(--f36);
  margin-bottom: var(--f0);
  color: #101828;
  font-family: var(--font-epilogue);
}

.verifyComponent .description,
.signUpComponent .description,
.resetPasswordComponent .description,
.loginComponent .description,
.forgotPasswordComponent .description,
.emailVarificationComponent .description {
  line-height: var(--f21);
  font-weight: var(--fw500);
  font-size: var(--f16);
  text-align: center;
  color: #667085;
  font-family: var(--font-satoshi);
  margin-bottom: var(--f0);
  margin-top: var(--f16);
}

.emailVarificationComponent .description {
  color: #475467;
  margin-top: var(--f19);
}

.emailVarificationComponent .detailsComponent p:nth-child(3) {
  margin-top: var(--f20);
}

.verifyComponent .description {
  line-height: var(--f30);
}

.verifyComponent .description:last-child,
.resetPasswordComponent .description:last-child,
.forgotPasswordComponent .description:last-child,
.emailVarificationComponent .description:last-child {
  padding-bottom: var(--f24);
}

.emailVarificationComponent .devider span {
  color: #101828 !important;
  font-family: var(--font-satoshi);
  font-style: var(--fw400);
  font-size: var(--f12);
  line-height: var(--f16);
  padding: var(--f0) var(--f17);
}

.emailVarificationComponent .devider span::before {
  width: 30% !important;
}

.emailVarificationComponent .devider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--f0) !important;
  margin-top: var(--f26) !important;
}

.emailVarificationComponent .buttonContainer {
  margin-top: var(--f8);
  margin-bottom: var(--f40);
}

.emailVarificationComponent .buttonContainer button {
  padding-left: var(--f20);
  padding-right: var(--f20);
  justify-content: center;
  align-items: center;
  width: 201px;
  height: var(--f48);
  display: flex;
  background: white;
  border: var(--f1) solid #d0d5dd;
  border-radius: var(--f8);
  color: #101828;
  font-size: var(--f16);
  line-height: var(--f20);
  font-weight: var(--fw500);
  column-gap: 9px;
}

.emailVarificationComponent .buttonContainer img {
  width: var(--f24);
  height: var(--f24);
}

.forgotPasswordComponent .backButton,
.emailVarificationComponent .backButton {
  line-height: var(--f24);
  font-weight: var(--fw700);
  font-size: var(--f16);
  border: 1px solid #d0d5dd;
  padding: var(--f10);
  background-color: white;
  border-radius: var(--f8);
  width: 100%;
  height: var(--f44);
  display: flex;
  color: #101828;
  align-items: center;
  justify-content: center;
}

.forgotPasswordComponent .backButton {
  margin-top: var(--f4);
}

.blankDiv {
  height: var(--f170);
}

.signUpComponent .submitButton,
.resetPasswordComponent .submitButton,
.loginComponent .submitButton,
.forgotPasswordComponent .submitButton {
  padding-top: var(--f12);
}
.loginComponent,
.signUpComponent {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.questionButtons {
  height: auto !important;
  overflow-y: auto;
}

.verifyComponent button,
.signUpComponent .submitButton button,
.resetPasswordComponent .submitButton button,
.loginComponent .submitButton button,
.forgotPasswordComponent .submitButton button {
  color: white;
  border: 1px solid #c35a1f;
  padding: var(--f10);
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  line-height: var(--f24);
  font-weight: var(--fw700);
  font-size: var(--f16);
  height: var(--f44);
  border-radius: var(--f8);
  background-color: #f75f18;
  width: 100%;
  transition: 0.5s all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verifyComponent button:hover,
.signUpComponent .submitButton button:hover,
.resetPasswordComponent .submitButton button:hover,
.loginComponent .submitButton button:hover,
.forgotPasswordComponent .submitButton button:hover {
  background-color: #e8450e;
}

.forgotPasswordComponent .submitButton button {
  margin-top: var(--f16);
}

.loginComponent .forgotPasswordTitle {
  display: flex;
  justify-content: flex-end;
}

.loginComponent .forgotPasswordTitle span {
  color: #f75f18;
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: var(--f24);
  font-family: var(--font-satoshi);
}

.signUpComponent .otherDescription,
.loginComponent .otherDescription {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.signUpComponent .otherDescription {
  height: 10vh;
}

.signUpComponent .termsDescritions {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}
.signUpComponent .termsDescritions p {
  color: rgb(102 112 133 / 1);
  line-height: var(--f24);
  font-size: var(--f14);
  text-align: center;
  font-weight: var(--fw500);
  padding-left: var(--f16);
  padding-right: var(--f16);
  margin-top: var(--f12);
  margin-bottom: var(--f0);
}

.signUpComponent .termsDescritions u {
  cursor: pointer;
  text-underline-offset: var(--f4);
}

.signUpComponent .otherDescription p,
.loginComponent .otherDescription p {
  color: #7d7d8a;
  line-height: var(--f24);
  font-weight: var(--fw500);
  font-size: var(--f16);
}
.signUpComponent .otherDescription a,
.loginComponent .otherDescription a {
  text-decoration: none;
}

.signUpComponent .otherDescription span,
.loginComponent .otherDescription span {
  color: #e8450e;
  font-weight: var(--fw700);
  cursor: pointer;
  font-size: var(--f16);
  margin-left: var(--f6);
  line-height: var(--f24);
}

.questionComponent header {
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  padding-left: var(--f32);
  align-items: center;
  padding-right: var(--f32);
  background-color: rgb(255 255 255/1);
  justify-content: space-between;
  height: var(--f80);
  display: flex;
}

.questionComponent header .backbutton {
  justify-content: space-between;
  width: 54%;
  display: flex;
}

.questionComponent header .backbutton button {
  color: #101828 !important;
  border: #d0d5dd 1px solid !important;
  background: #ffffff !important;
  border-radius: 8px;
  height: 44px;
  width: 99px;
  padding: 10px, 16px, 10px, 16px;
  transition: 0.3s all;
}

.questionComponent header .backbutton button:hover {
  border-color: rgb(16 24 40/1) !important;
  transform: translateX(-3px);
}

.questionComponent header .logo {
  align-items: center;
  color: #101828;
  column-gap: var(--f10);
  display: flex;
  font-weight: var(--fw800);
  font-size: var(--f18);
  line-height: var(--f22);
  justify-content: center;
}

.questionComponent .questionProgress {
  transition: 0.5s all;
  border-bottom: 4px solid #f75f18;
}

.questionComponent .questionsSection {
  padding-bottom: var(--f32);
  padding-top: var(--f32);
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  display: flex;
}
/* .questionComponent .section {
  background-color: red !important;
} */

.questionComponent .questionsSection .section .questionButtons {
  max-height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.questionComponent .questionsSection .section {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.questionComponent .questionsSection .section .title {
  color: #101828;
  font-weight: var(--fw700);
  font-size: var(--f24);
  line-height: var(--f32);
  margin-top: var(--f0);
  margin-bottom: var(--f14);
  font-family: var(--font-epilogue);
  text-align: center;
  width: 100%;
}

@media (420px <= width <= 1440px) {
  .questionComponent .questionsSection .section .title {
    /* margin-bottom: 0; */
  }
  .questionComponent .questionsSection {
    /* background-color: red !important; */
    /* min-height: 1426px; */
  }
}

.questionComponent .questionsSection .section .questionButtons {
  padding-left: var(--f80);
  padding-right: var(--f80);
}

.questionComponent .questionsSection .section .questionButtons button {
  align-items: center;
  background-color: #fcfcfd;
  border: 1px solid #d0d5dd;
  cursor: grab;
  display: flex;
  height: var(--f56);
  width: var(--f420);
  transition: all 0.4s;
  padding: var(--f16) var(--f18);
  color: #101828;
  border-radius: var(--f8);
  text-wrap: nowrap;
  margin-top: var(--f16);
}

.questionComponent .questionsSection .section .questionButtons button span {
  margin: auto;
  font-size: var(--f16);
  font-weight: var(--fw500);
  line-height: var(--f21);
}

.questionComponent .questionsSection .section .questionButtons button:hover {
  background-color: #fcfcfd;
  border: #475467 1px solid;
}

.questionComponent
  .questionsSection
  .section
  .questionButtons
  button:last-child {
  transition: all 0.4s;
  color: rgb(255 255 255/1);
  line-height: var(--f24);
  font-weight: var(--fw700);
  font-size: var(--f16);
  padding-bottom: var(--f10);
  padding-top: var(--f10);
  background-color: #f75f18;
  border-radius: var(--f8);
  width: 100%;
  border: 1px solid #c35a1f;
  margin-top: var(--f40);
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  margin-bottom: var(--f8);
  text-align: center;
  display: flex;
  border: none !important;
  justify-content: center;
  height: var(--f44);
}

.questionComponent .questionsSection .section .questionButtons button.selected {
  transition: 0.2s all;
  background-color: #fff5ed;
  border: #f75f18 1px solid;
  cursor: grab;
  display: flex;
  align-items: center;
  height: var(--f56);
  width: var(--f420);
}

.questionComponent
  .questionsSection
  .section
  .questionButtons
  button:last-child:hover {
  background-color: #e8450e;
  border: none !important;
}

.questionComponent
  .questionsSection
  .section
  .questionButtons
  button.selected
  img {
  transition: 0.4s all;
  /* position: absolute; */
}

.questionComponent
  .questionsSection
  .section
  .questionButtons
  button.selected
  span {
  margin: auto;
}

@media (max-width: 1024px) {
  .questionComponent header .backbutton {
    width: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .signUpComponent .otherDescription {
    height: 8vh;
  }
  .detailsComponent {
    margin-bottom: var(--f6);
  }
  .signUpComponent .termsDescritions p {
    line-height: var(--f20);
    font-size: var(--f14);
    margin: auto;
  }
  .questionComponent .questionsSection .section .questionButtons button {
    height: var(--f46);
    padding-bottom: var(--f13);
    padding-top: var(--f13);
    margin-top: var(--f10);
  }

  .signUpComponent .submitButton,
  .resetPasswordComponent .submitButton,
  .loginComponent .submitButton,
  .forgotPasswordComponent .submitButton {
    padding-top: var(--f12);
  }
  .verifyComponent .description:last-child,
  .signUpComponent .description:last-child,
  .resetPasswordComponent .description:last-child,
  .loginComponent .description:last-child,
  .forgotPasswordComponent .description:last-child,
  .emailVarificationComponent .description:last-child {
    padding-bottom: var(--f0);
    margin-bottom: var(--f0);
    margin-top: var(--f2);
  }

  .verifyComponent .title,
  .signUpComponent .title,
  .resetPasswordComponent .title,
  .loginComponent .title,
  .forgotPasswordComponent .title,
  .emailVarificationComponent .title {
    line-height: var(--f16);
    padding-bottom: var(--f0);
    padding-top: var(--f0);
    margin-top: var(--f12);
    font-size: var(--f20);
  }

  .questionComponent
    .questionsSection
    .section
    .questionButtons
    button:last-child {
    margin-top: var(--f16);
  }
  .questionComponent .questionsSection .section .questionButtons {
    padding-left: var(--f24);
    padding-right: var(--f24);
  }
  .questionComponent .questionsSection {
    padding-bottom: var(--f10);
    padding-top: var(--f10);
  }
  .questionComponent .questionsSection .section .title {
    font-size: var(--f18);
    line-height: var(--f20);
    margin-top: var(--f12);
  }
  .questionComponent header {
    padding-bottom: var(--f16);
    padding-top: var(--f16);
    padding-left: var(--f32);
    padding-right: var(--f32);
  }
  .verifyComponent .description,
  .signUpComponent .description,
  .resetPasswordComponent .description,
  .loginComponent .description,
  .forgotPasswordComponent .description,
  .emailVarificationComponent .description {
    font-size: var(--f14);
    padding-bottom: var(--f16);
  }
  .blankDiv {
    height: var(--f170);
  }
}
.scrollDiv {
  position: fixed;
  left: 25%;
  right: 25%;
  height: calc(100vh - 80px);
  overflow: auto;
}
@media screen and (max-width: 1498px) {
  .scrollDiv {
    position: fixed;
    left: 25%;
    right: 25%;
    height: calc(100vh - 100px);
    overflow: auto;

    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1098px) {
  .signUpComponent .termsDescritions p {
    margin-bottom: var(--f8);
  }
  .signUpComponent .otherDescription {
    height: 10vh;
  }
  .detailsComponent {
    margin-bottom: var(--f0);
  }

  .signUpComponent .title {
    line-height: var(--f10);
    margin-top: var(--f8);
    font-size: var(--f14);
  }

  .signUpComponent .description:last-child {
    margin-top: var(--f0);
  }

  .signUpComponent .description {
    font-size: var(--f10);
    padding-bottom: var(--f10);
  }

  .signUpComponent .termsDescritions p {
    line-height: var(--f14);
    font-size: var(--f10);
    margin-top: var(--f8);
  }

  .signUpComponent .otherDescription p {
    line-height: var(--f10);
    font-size: var(--f9);
  }
  .signUpComponent .otherDescription span {
    line-height: var(--f10);
    font-size: var(--f9);
  }
}

@media screen and (max-width: 1098px) {
  .detailsComponent {
    margin-bottom: var(--f0);
  }

  .signUpComponent .title {
    line-height: var(--f10);
    margin-top: var(--f8);
    font-size: var(--f14);
  }

  .signUpComponent .description:last-child {
    margin-top: var(--f0);
  }

  .signUpComponent .description {
    font-size: var(--f10);
    padding-bottom: var(--f10);
  }

  .signUpComponent .termsDescritions p {
    line-height: var(--f14);
    font-size: var(--f10);
    margin-top: var(--f8);
  }

  .signUpComponent .otherDescription p {
    line-height: var(--f10);
    font-size: var(--f9);
    margin-top: var(--f6);
  }
  .signUpComponent .otherDescription span {
    line-height: var(--f10);
    font-size: var(--f9);
    margin-top: var(--f6);
  }
}

@media screen and (max-width: 962px) {
  .signUpComponent .title {
    line-height: var(--f8);
    margin-top: var(--f6);
    font-size: var(--f12);
    margin-bottom: var(--f2);
  }

  .signUpComponent .termsDescritions p {
    line-height: var(--f10);
    font-size: var(--f8);
    margin-top: var(--f8);
  }

  .signUpComponent .otherDescription p {
    line-height: var(--f8);
    font-size: var(--f9);
    margin-top: var(--f6);
  }
  .signUpComponent .otherDescription span {
    line-height: var(--f8);
    font-size: var(--f9);
    margin-top: var(--f6);
  }
}

.nodatacomponent {
  height: var(--f600);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: var(--f8);
}
.spinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

:global(.ant-spin).nodatacomponent :global(.ant-spin-dot-item) {
  background-color: #e8450e;
}
