.priceContainer>button {
    border: 1px solid #c35a1f;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    background-color: #f75f18;
    height: 42px;
    display: flex;
    padding-right: 1.25rem;
    align-items: center;
    width: 100%;
    padding-inline: 24px;
    justify-content: center;
    border-radius: 0.5rem;
    transition: 0.5s all;
    box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.priceContainer>button:hover {
    background-color: #e8450e;
}

.priceContainer>button span {
    color: white;

    font-weight: var(--fw700) !important;
    font-size: var(--f16);
    line-height: 24px;
    text-wrap: nowrap;
}

.priceContainer>button:disabled {
    border: 1px solid #D0D5DD;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    background-color: var(--white);
    height: 42px;
    display: flex;
    padding-right: 1.25rem;
    align-items: center;
    width: 100%;
    padding-inline: 24px;
    justify-content: center;
    border-radius: 0.5rem;
    transition: 0.5s all;
    /* box-shadow: 0px 3px 2px 0px #ffffff3d inset; */
}



.priceContainer>button:disabled span {
    color: #D0D5DD;
    font-weight: var(--fw700);
    font-size: var(--f16);
    line-height: 24px;
    font-family: var(--font-satoshi);
    text-wrap: nowrap;
}