.emailConnectListComponent {
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
}

.emailContent {
  border-radius: var(--f16);
  justify-content: space-between;
  padding: var(--f32);
  box-shadow: var(--f0) var(--f4) var(--f40) var(--f0) #1018280d;
  transition: 0.2s all;
  background-color: white;
  align-items: center;
  cursor: pointer;
  width: 627px;
  height: var(--f125);
  margin-top: var(--f16);
  display: flex;
}

.emailContent:first-child {
  margin-top: var(--f0);
}

.emailContent:hover {
  border: var(--f1) solid #101828;
}

.icone {
  justify-content: center;
  align-items: center;
  display: flex;
}

.icone div:first-child {
  height: var(--f60);
  width: var(--f60);
  border: 1.5px solid #fdd0ab;
  border-radius: var(--f12);
  background-color: #fff5ed;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icone div:nth-child(2) {
  margin: var(--f0) var(--f24);
  height: var(--f38);
  width: 1.5px;
  background: #e5e7e9;
}

.icone div:last-child {
  justify-content: center;
  row-gap: var(--f8);
  align-items: flex-start;
  flex-direction: column;
  display: flex;
}

.icone div:last-child span:first-child {
  font-size: var(--f20);
  line-height: var(--f24);
  font-weight: var(--fw700);
  font-family: var(--font-epilogue);
  color: var(--primary-black);
}

.icone div:last-child span:last-child {
  font-size: var(--f16);
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  line-height: 21.6px;
  color: var(--gray-light-500);
}

@media screen and (max-width: 1400px) {
  .icone div:last-child span:first-child {
    font-size: var(--f16);
    font-weight: var(--fw600);
  }

  .icone div:last-child span:last-child {
    font-size: var(--f14);
  }

  .emailContent {
    height: var(--f110);
    width: 35%;
  }
}
