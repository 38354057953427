.mainCard {
  width: 100%;
  padding: 0 24px 24px;
  box-shadow: var(--shadow);
}

:global(.ck.ck-reset_all) :global(.ck.ck-sticky-panel) :global(.ck.ck-sticky-panel__content) :global(.ck.ck-toolbar_grouping) :global(.ck.ck-toolbar__items) .pictureIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  flex-grow: 0;
}

:global(.ck.ck-reset_all) :global(.ck.ck-sticky-panel) :global(.ck.ck-sticky-panel__content) :global(.ck.ck-toolbar_grouping) :global(.ck.ck-toolbar__items) {
  flex-grow: 0;
  padding-left: 15px;
}

.mainCard button.upgradeButton {
  display: flex;
  height: 42px;
  width: 126px;
  align-items: center;
  justify-content: center;
  border-radius: var(--f8);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-border-color);
  font-size: var(--f14);
  line-height: 20px;
  font-weight: var(--fw700);
  transition: 0.5s all;
  color: var(--white);
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.mainCard button.upgradeButton:hover {
  background-color: #e8450e;
}

.cardTitle {
  font-size: var(--f20);
  color: var(--primary-black);
  font-weight: var(--fw700);
  line-height: 24px;
}

.cardDescription {
  font-size: var(--f16);
  font-weight: var(--fw500);
  color: var(--gray-light-500);
}

.searchInputDropdownContainor {
  padding: 8px;
}

.prefixLabel {
  font-size: var(--f16);
  font-weight: var(--fw700);
  padding-left: 8px;
  padding-right: 8px;
  color: var(--gray-light-500);
}

.ContentDescription {
  font-size: var(--f16);
  font-weight: var(--fw500);
  color: var(--gray-light-500);
  padding: 10px 0px 20px 0px;
}

.selectEmail {
  margin-left: 50px;
  width: 255px;
}

.templateContainer {
  height: 100%;
  margin-top: 30px;
}

.leftContainer,
.rightContainer {
  display: flex;
  background-color: var(--white, #fff);
  flex-direction: column;
  padding: var(--f24);
  min-height: 92vh;
  box-shadow: var(--shadow);
  border-radius: var(--f16);
}

.subjectInput,
.descriptionInput {
  width: 100% !important;
}

.subjectInput .ant-input {
  height: 58px;
  padding: 18px var(--f16);
  gap: var(--f4);
  border-radius: var(--f8);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: var(--font-satoshi);
  font-size: var(--f16);
  font-weight: var(--fw500);
  line-height: 21.6px;
  text-align: left;
}

.descriptionInput {
  min-height: 600px !important;
}

.descriptionInput .ant-input {
  min-height: 600px !important;
  padding: 18px var(--f16);
  border-radius: var(--f8);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.subjectInput .ant-input::placeholder,
.descriptionInput .ck.ck-editor__editable>.ck-placeholder::before {
  font-family: var(--font-satoshi) !important;
  font-size: var(--f16) !important;
  font-weight: var(--fw500) !important;
  line-height: 21.6px;
  color: rgba(52, 64, 84, 0.5) !important;
}

.priceContainer {
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  padding: 20px 10px 20px 0px;
}

.priceContainer>button {
  border: 1px solid var(--primary-border-color);
  padding-top: var(--f8);
  padding-bottom: var(--f8);
  padding-left: var(--f20);
  background-color: var(--primary-color);
  height: 42px;
  display: flex;
  padding-right: var(--f20);
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: var(--f8);
  transition: 0.5s all;
  font-weight: var(--fw700) !important;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.priceContainer>button:hover {
  background-color: #e8450e;
}

.priceContainer>button span {
  color: white;

  font-weight: var(--fw700) !important;
  font-size: var(--f16);
  line-height: 24px;
  text-wrap: nowrap;
}

.priceContainer>button:disabled {
  border: 1px solid var(--gray-light-300);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  background-color: var(--white);
  height: 42px;
  display: flex;
  padding-right: 1.25rem;
  align-items: center;
  width: 100%;
  padding-inline: 24px;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.5s all;
  /* box-shadow: 0px 3px 2px 0px #ffffff3d inset; */
}

.priceContainer>button:disabled span {
  color: var(--gray-light-300);
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: 24px;
  font-family: var(--font-satoshi);
  text-wrap: nowrap;
}

.imgGoogle {
  width: 12px;
  position: absolute;
  padding-left: 10px;
}

.searchInputDropdown {
  padding: 8px 11px;
}

.selectMailname {
  font-weight: var(--fw500);
  font-size: var(--f14);
  color: var(--gray-light-700);
}

.selectTemplatename {
  font-weight: var(--fw400);
  font-size: var(--f14);
  color: var(--black-color);
}

.selectMailtext {
  font-weight: var(--fw400);
  font-size: var(--f12);
  color: var(--gray-light-600);
}

.btnCreateEmail {
  font-weight: var(--fw500);
  font-size: var(--f14);
  color: var(--primary-color);
}

.selectIcontext {
  font-size: var(--f12);
  padding-left: 10px;
  color: var(---Tag-Green-500) !important;
}

.selctBarFooter {
  font-weight: var(--fw500);
  font-size: var(--f14);
  padding: 15px;
  color: var(--primary-color);
  cursor: pointer;
}

.dateDivider {
  background: #d9d9d9;
  width: 2px;
  height: 22px;
  margin-right: 10px;
}

.templatePlugin :global(.ant-select) {
  width: 232px;
  height: 36px;
}

.templatePlugin :global(.ant-select) :global(.ant-select-selector):global(.ant-select-selection-item) span {
  font-weight: var(--fw500);
  font-size: var(--f14);
  padding: 15px;
  color: var(--primary-color);
  cursor: pointer;
}

.templatePlugin :global(.ant-select-selection-placeholder) {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18.9px !important;
  color: #344054 !important;
}

.templatePlugin :global(.ant-select-selector) {
  border: 1px solid #eaecf0 !important;
  min-width: 151px !important;
  max-width: 250px !important;
  width: auto !important;
}

:global(.ck.ck-reset_all) :global(.ck.ck-sticky-panel) :global(.ck.ck-sticky-panel__content) :global(.ck.ck-toolbar_grouping) :global(.ck.ck-toolbar__items) .templatePlugin :global(.ant-select) {
  position: relative;
}

:global(.ck.ck-reset_all) :global(.ck.ck-sticky-panel) :global(.ck.ck-sticky-panel__content) :global(.ck.ck-toolbar_grouping) :global(.ck.ck-toolbar__items) .templatePlugin :global(.ant-select-arrow) {
  position: absolute;
  top: 35%;
}

.pictureModal .ant-modal-content {
  height: 282px !important;
  border-radius: 16px !important;
  padding: var(--f0);
}

.confirmModal .ant-modal-content {
  height: 260px;
  border-radius: 16px !important;
  padding: var(--f0);
}

.pictureModalContent {
  padding: 24px 24px 0px var(--f24);
}

.modalDesc {
  margin-top: var(--f20);
}

.modalHeader {
  width: 346px;
  height: var(--f56);
}

.confirmModalHeader {
  width: 346px;
  height: 52px;
}

.modalHeader span,
.confirmModalHeader span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw700);
  font-size: var(--f20);
  color: var(--gray-light-700);
}

.confirmModalHeader span {
  font-family: var(--font-epilogue);
}

.modalDesc {
  width: 402px;
  /* height: 82px; */
  display: flex;
  flex-direction: column;
  gap: var(--f20);
}

.modalDesc span {
  font-family: var(--font-satoshi);
  /* font-family: "Satoshi"; */
  font-size: var(--f16);
  font-weight: var(--fw500);
  color: var(--gray-light-700);
}

.pictureModalFooter,
.confirmModalFooter {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--f10);
  height: 88px;
  max-width: 465px;
  align-items: center;
  border-top: 2px solid #f3f4f6;
  margin-top: var(--f24);
  padding-right: var(--f24);
}

.confirmModalFooter {
  margin-top: var(--f0);
  padding-right: var(--f24);
}

.pictureModalFooter button,
.confirmModalFooter button {
  width: 104px;
  height: var(--f40);
  padding: 10px var(--f10);
  gap: var(--f8);
  border-radius: var(--f12);
  border: 1px;
}

.pictureModalFooter button:nth-child(2),
.confirmModalFooter button:nth-child(2) {
  background: var(--white, #ffffff);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  font-size: var(--f14);
  font-weight: var(--fw700);
  color: var(--primary-black);
  box-shadow: 0px -4px 4px 0px #10182814 inset;
}

.pictureModalFooter button:nth-child(1),
.confirmModalFooter button:nth-child(1) {
  background: #e8450e;
  border: 1px solid var(--primary-border-color, #c35a1f);
  color: var(--white);
  font-weight: var(--fw600);
  font-size: var(--f14);
  box-shadow: 0px -4px 4px 0px #1018281f inset;
}

.pictureModal .ant-modal-close,
.pictureModal .ant-modal-close:hover,
.confirmModal .ant-modal-close,
.confirmModal .ant-modal-close:hover {
  width: 24px !important;
  height: 24px !important;
  margin-top: var(--f10);
  margin-right: var(--f6);
  color: 1.5px solid var(--black-color, #000000);
  background-color: var(--white);
}


.custome_selectOtption_lable {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
}

.emailType_logo {
  height: 34px;
}

.custome_selectOtption_lable svg {
  width: 16px;
  height: 16px;
}