/* * {
    background-color: rgba(16, 24, 40, 0.35) !important;
} */
.pictureModal .ant-modal-content {
  height: 100% !important;
  border-radius: 16px !important;
  padding: var(--f0);
}

.confirmModal .ant-modal-content {
  height: 260px;
  border-radius: 16px !important;
  padding: var(--f0);
}

.pictureModalContent {
  padding: 24px 24px 24px var(--f24);
}

.pictureModalContent .modalDesc {
  margin-top: var(--f20);
}

.modalHeader {
  width: 346px;
  height: var(--f56);
}

.confirmModalHeader {
  width: 346px;
  height: 52px;
}

.modalHeader span,
.confirmModalHeader span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw700);
  font-size: var(--f20);
  color: var(--gray-light-700);
}

.confirmModalHeader span {
  font-family: var(--font-epilogue);
}

.pictureModalContent .modalDesc {
  width: 734px;
  height: 244px;
  align-items: center;
  border: 1px dotted #98a2b3;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  gap: 5px;
}

.pictureModalContent .modalDesc span {
  font-family: var(--font-satoshi);
  font-size: var(--f16);
  text-align: -webkit-center;
  font-weight: var(--fw500);
  /* color: var(--gray-light-600); */
}

.pictureModalContent .modalDesc span .orDivider {
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  text-align: center;
  font-weight: var(--fw400);
  color: var(--gray-light-600);
}

.pictureModalFooter,
.confirmModalFooter {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--f10);
  height: 88px;
  max-width: 100%;
  align-items: center;
  border-top: 2px solid #f3f4f6;
  margin-top: var(--f24);
  padding-right: var(--f24);
}

.confirmModalFooter {
  margin-top: var(--f0);
  padding-right: var(--f24);
}

.pictureModalFooter button,
.confirmModalFooter button {
  width: 104px;
  height: var(--f40);
  padding: 10px var(--f10);
  gap: var(--f8);
  border-radius: var(--f12);
  border: 1px;
}

.pictureModalFooter button:nth-child(2),
.confirmModalFooter button:nth-child(2) {
  background: var(--white, #ffffff);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  font-size: var(--f14);
  font-weight: var(--fw700);
  color: var(--primary-black);
}

.pictureModalFooter button:nth-child(1),
.confirmModalFooter button:nth-child(1) {
  border: 1px solid var(--primary-border-color);
  background-color: var(--primary-color);
  height: 42px;
  display: flex;
  align-items: center;
  color: var(--white, #ffffff);
  justify-content: center;
  border-radius: var(--f8);
  transition: 0.5s all;
  font-size: var(--f14);
  font-weight: var(--fw700) !important;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  transition: 0.5s all;
}

.pictureModalFooter>button:nth-child(1):hover {
  background-color: #e8450e;
}

.pictureModalFooter>button span {
  color: white;

  font-weight: var(--fw700) !important;
  font-size: var(--f16);
  line-height: 24px;
  text-wrap: nowrap;
}

.pictureModalFooter>button:disabled {
  border: 1px solid var(--gray-light-300);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  background-color: var(--white);
  height: 42px;
  display: flex;
  padding-right: 1.25rem;
  align-items: center;
  width: 100%;
  padding-inline: 24px;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.5s all;
}

.pictureModalFooter>button:disabled span {
  color: var(--gray-light-300);
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: 24px;
  font-family: var(--font-satoshi);
  text-wrap: nowrap;
}

.pictureModal .ant-modal-close,
.pictureModal .ant-modal-close:hover,
.confirmModal .ant-modal-close,
.confirmModal .ant-modal-close:hover {
  width: 24px !important;
  height: 24px !important;
  margin-top: var(--f10);
  margin-right: var(--f6);
  color: 1.5px solid var(--black-color, #000000);
  background-color: var(--white);
}

.priceContainer>button {
  border: 1px solid var(--primary-border-color);
  padding-top: var(--f8);
  padding-bottom: var(--f8);
  padding-left: var(--f20);
  background-color: var(--primary-color);
  height: 42px;
  display: flex;
  padding-right: var(--f20);
  align-items: center;
  width: 141px;
  justify-content: center;
  border-radius: var(--f8);
  transition: 0.5s all;
  font-weight: var(--fw700) !important;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.priceContainer>button:hover {
  background-color: #e8450e;
}

.priceContainer>button span {
  color: white;

  font-weight: var(--fw700) !important;
  font-size: var(--f16);
  line-height: 24px;
  text-wrap: nowrap;
}

.priceContainer>button:disabled {
  border: 1px solid var(--gray-light-300);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  background-color: var(--white);
  height: 42px;
  display: flex;
  padding-right: 1.25rem;
  align-items: center;
  width: 100%;
  padding-inline: 24px;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.5s all;
}

.priceContainer>button:disabled span {
  color: var(--gray-light-300);
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: 24px;
  font-family: var(--font-satoshi);
  text-wrap: nowrap;
}

.modalDesc {
  position: relative;
  text-align: flex-start;
  margin: 10px 10px 10px 0;
}


.modalDesc>input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0px;
  width: 734px;
  height: 244px;
  cursor: pointer;
}

.uploadText {
  text-align: center;
  padding-top: 80px;
}

.modalDesc label {
  font-weight: 600;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-width: 1px;
  padding: 0px !important;
  width: 734px;
  height: 244px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  gap: 5px;
  display: table;
}

.btncontainer {
  width: 734px;
  padding-top: 140px;
  height: 244px;
  text-align: -webkit-center;
}

.imgPreview {
  height: 244px !important;
}