.sidebar-top-menus {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--f18);
}

.sidebar-top-menus div {
  height: var(--f48);
  width: var(--f48);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--f8);
  margin: var(--f4) var(--f0);
}

.authContainer-component {
  min-height: var(--vh100);
}

.sidebar-top-menus div:hover,
.sidebar-top-menus div.top-menu-selected {
  border: 1px solid #fdd0ab;
  background-color: #fff5ed;
}

.log-out-menu {
  display: flex;
  cursor: pointer;
}

.log-out-menu img {
  margin-right: var(--f4);
}

.log-out-menu span {
  margin-right: var(--f2);
}

.sidebar-bottom-menus {
  height: auto;
  margin-top: -0.1px;
  padding-top: 0.1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: var(--f22);
  position: absolute;
}

.sidebar-bottom-menus div {
  height: var(--f48);
  width: var(--f48);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--f8);
  margin: var(--f8) var(--f0);
}

.sidebar-bottom-menus div:last-child {
  margin-top: var(--f16);
}

.sidebar-bottom-menus div:hover,
.sidebar-bottom-menus .setting-selected {
  border: 1px solid #fdd0ab;
  background-color: #fff5ed;
}

.sidebar-bottom-menus .profile,
.sidebar-bottom-menus .profile :hover {
  background-color: rgb(95 247 24 / 20%) !important;
  border-radius: 50px !important;
  border: none !important;
  font-size: var(--f16);
  line-height: var(--f22);
  color: rgba(52, 64, 84, 1);
  font-weight: var(--fw700);
}

.dashboard-layout aside {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
  padding: var(--f16) !important;
}

.disabled-input {
  background-color: white !important;
  color: black !important;
}

.sidebar-logo {
  border-bottom: 1px solid #eaecf0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 8px 25px 8px;
}

.sidebar-logo img {
  cursor: pointer;
  width: 36px;
  height: 36px;
}

.varification-component {
  background: #fffaeb;
  border: 1px solid #fedf89;
  transition: 0.5s all;
  padding-left: var(--f36);
  padding-right: var(--f36);
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 68px;
  display: flex;
}

.varification-component .varification-component-description {
  column-gap: var(--f32);
  justify-content: center;
  align-items: center;
  display: flex;
}

.varification-component .button {
  background-color: white;
  padding: 0px var(--f10);
  border-radius: var(--f8);
  border: 1px solid #d0d5dd;
  font-size: var(--f14);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 189px;
  line-height: var(--f20);
  height: var(--f40);
  color: #101828;
  font-weight: var(--fw700);
  display: flex;
}

.varification-component .ant-spin .ant-spin-dot-item {
  background-color: #d49c04;
}

.varification-component .varification-component-description div {
  justify-content: center;
  align-items: center;
  width: var(--f40);
  display: flex;
  height: var(--f40);
  border: 1px solid #fedf89;
  background-color: white;
  border-radius: var(--f6);
  cursor: pointer;
}

.varification-component label {
  color: #101828;
  font-size: var(--f16);
  font-weight: var(--fw500);
  line-height: var(--f20);
}

.sidebar .ant-popover-inner {
  height: 33px !important;
  width: auto !important;
  padding: 0px 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.sidebar .ant-popover-title {
  margin: 0 !important;
  min-width: auto !important;
  cursor: pointer;
}

.dashboard-layout {
  overflow: auto;
}

.dashboard-layout-content {
  overflow: auto;
  padding: var(--f32);
}

.dashboard-layout .dashboard-header {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: white;
  height: 84px;
  padding: 22px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-layout .dashboard-header h4 {
  display: flex;
  column-gap: var(--f16);
  align-items: center;
  margin: 12px 0px;
  justify-content: center;
}

.dashboard-layout .dashboard-header h4 span {
  color: var(--primary-black);
  font-weight: var(--fw700);
  font-size: var(--f20);
  line-height: var(--f24);
  font-family: var(--font-epilogue) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  gap: 6px;
  /* height: var(--f20); */
}

.dashboard-layout .dashboard-header h4 img {
  cursor: pointer;
}

.dashboard-layout .dashboard-header div:last-child {
  align-items: center;
  display: flex;
}

.dashboard-layout .dashboard-header div:last-child .progress-container {
  justify-content: center;
  width: 373px;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.dashboard-layout .dashboard-header div:last-child .progress-container label {
  justify-content: space-between;
  height: var(--f19);
  align-items: flex-start;
  /* width: fit-content;
  padding-inline: 10px; */
  width: max-content;
  align-items: center;
  gap: 10px;
  font-weight: var(--fw700);
  display: flex;
  padding-top: 1.5px;
  margin-bottom: var(--f8);
}

.dashboard-layout
  .dashboard-header
  div:last-child
  .progress-container
  label
  span:first-child {
  font-weight: var(--fw700);
  line-height: var(--f19);
  font-size: var(--f14);
}

.dashboard-layout
  .dashboard-header
  div:last-child
  .progress-container
  label
  span:last-child {
  font-size: var(--f12);
  color: #344054;
  line-height: var(--f16);
  font-weight: var(--fw400);
}

.dashboard-layout
  .dashboard-header
  div:last-child
  .progress-container
  .progress {
  background: #eaecf0;
  border: #e5e7e9 1px solid;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  height: var(--f10);
  width: 373px;
  border-radius: 9999px;
}

.dashboard-layout
  .dashboard-header
  div:last-child
  .progress-container
  .progress
  div {
  border-radius: 9999px;
  background-color: #f52e2e;
  width: 240px;
  height: var(--f10);
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.dashboard-layout .dashboard-header div:last-child .navbar-part-stroke {
  background: #e5e7e9;
  width: 1.5px;
  margin: var(--f0) var(--f24);
  height: var(--f30);
}

.dashboard-layout .dashboard-header div:last-child .navbar-part-icones {
  background-color: #f9fafb;
  border: #eaecf0 1px solid;
  align-items: center;
  transition: 0.5s all;
  height: var(--f48);
  cursor: pointer;
  width: var(--f48);
  display: flex;
  border-radius: 9999px;
  justify-content: center;
}
.dashboard-layout .dashboard-header div:last-child div:nth-child(3) {
  margin-right: var(--f8);
}
.dashboard-layout .dashboard-header div:last-child div:nth-child(4) {
  margin-left: var(--f8);
}

.dashboard-layout .dashboard-header div:last-child .navbar-part-icones:hover {
  background-color: #eaecf0;
  border: #667085 1px solid;
}

.dashboard-layout .dashboard-header div:last-child button {
  border: 1px solid #c35a1f;
  padding-top: var(--f8);
  padding-bottom: var(--f8);
  padding-left: var(--f20);
  background-color: #f75f18;
  height: 42px;
  display: flex;
  padding-right: var(--f20);
  align-items: center;
  width: 141px;
  justify-content: center;
  border-radius: var(--f8);
  transition: 0.5s all;
  font-weight: var(--fw700) !important;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.dashboard-layout .dashboard-header div:last-child button:hover {
  background-color: #e8450e;
}

.dashboard-layout .dashboard-header div:last-child button span {
  color: white;
  font-weight: var(--fw700);
  font-size: var(--f14);
  line-height: var(--f20);
}

.top-menu-selected > .svgIcons {
  fill: #f75f18;
}

.antd-popover-open > .svgIcons {
  stroke: #f75f18;
}

.top-menu-selected > .templatesvgIcons,
.top-menu-selected > .subscriptionSvgIcons,
.top-menu-selected > .deliverabilitySvgIcons {
  stroke: #f75f18;
}

.templatesvgIcons {
  stroke: var(--primary-black);
}

.settingSvgIcons,
.subscriptionSvgIcons,
.deliverabilitySvgIcons {
  stroke: var(--primary-black);
}

.setting-selected > .settingSvgIcons {
  stroke: #f75f18;
}

.antd-popover-open > .templatesvgIcons,
.antd-popover-open > .subscriptionSvgIcons,
.antd-popover-open > .deliverabilitySvgIcons {
  stroke: #f75f18;
}

.setting:hover .settingSvgIcons,
.sidebar-top-menus > div:hover .subscriptionSvgIcons,
.sidebar-top-menus:hover .settingSvgIcons,
.sidebar-top-menus > div:hover .deliverabilitySvgIcons,
.sidebar-top-menus > div:hover .templatesvgIcons {
  stroke: #f75f18;
}

.sidebar-top-menus > div:hover .svgIcons {
  fill: #f75f18;
}

.rounded_btn {
  border-radius: 100px !important;
  padding: 8px 20px !important;
  border: 1px solid #fdd0ab !important;
  background-color: #fff5ed !important;
  width: 100% !important;
  margin-right: var(--f16);
}

.rounded_btn > span {
  text-wrap: nowrap;
  color: var(--primary-black) !important;
  font-weight: var(--fw700) !important;
  font-size: var(--f16) !important;
  line-height: var(--f24) !important;
}

.rounded_btn > span > span {
  text-wrap: nowrap;
  color: var(--primary-black) !important;
  font-weight: var(--fw900) !important;
  font-size: var(--f16) !important;
  line-height: var(--f24) !important;
}
.template_title_header {
  width: 260px !important;
  height: 40px !important;
}

.template_title {
  width: 260px;
  height: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  font-family: var(--font-epilogue);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #101828;
}

.template_title:hover,
.template_title:focus {
  border-color: #fcae75 !important;
  box-shadow: 0 0 0 4px #fcae7530 !important;
}

.template_title:focus-visible {
  outline: 1px solid #eaecf0 !important;
}

header {
  background-color: white !important;
  /* border: 1px solid !important; */
  /* box-shadow: #e5e7e9; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.public_dashboard_header h4 {
  color: var(--primary-black);
  font-weight: var(--fw700);
  font-size: var(--f20);
  line-height: var(--f24);
  font-family: var(--font-epilogue) !important;
  /* height: var(--f20); */
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.betaBtn {
  /* padding: 6px 12px; */
  /* margin-left: 5px; */
  border-radius: 5px;
  background-color: transparent;
  /* font-weight: 500; */
  /* font-size: 16px; */
  border: none !important;
  /* color: rgba(68, 68, 235, 0.993); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 0 !important;
}
.betaBtn > span {
  padding: 6px 12px;
  /* margin-left: 5px; */
  margin-top: 0 !important;
  border-radius: 5px;
  background-color: #082ae715 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  border: none !important;
  color: #207de9 !important;

  display: flex !important;
  line-height: 21px !important;
  justify-content: center !important;
  align-items: center !important;
}
