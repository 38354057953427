/* * {
    background-color: rgba(16, 24, 40, 0.35) !important;
} */
.deleteModal .ant-modal-content {
  /* height: 282px !important; */
  border-radius: 16px !important;
  padding: var(--f0);
}

.template_modal .ant-modal-content {
  height: auto !important;
}

.confirmModal .ant-modal-content {
  height: 260px;
  border-radius: 16px !important;
  padding: var(--f0);
}

.deleteModalContent {
  padding: 24px 24px 0px var(--f24);
}

.modalDesc {
  margin-top: var(--f20);
}

.modalHeader {
  width: 346px;
  height: var(--f56);
}

.confirmModalHeader {
  width: 346px;
  height: 52px;
}

.modalHeader span,
.confirmModalHeader span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw700);
  font-size: var(--f20);
  color: var(--gray-light-700);
}

.confirmModalHeader span {
  font-family: var(--font-epilogue);
}

.modalDesc {
  width: 402px;
  /* height: 82px; */
  display: flex;
  flex-direction: column;
  gap: var(--f20);
}

.template_modal .modalDesc {
  height: auto !important;
}

.modalDesc span {
  font-family: var(--font-satoshi);
  font-size: var(--f16);
  font-weight: var(--fw500);
  color: var(--gray-light-700);
}

.deleteModalFooter,
.confirmModalFooter {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--f10);
  height: 88px;

  max-width: 465px;
  align-items: center;
  border-top: 2px solid #f3f4f6;
  margin-top: var(--f24);
  padding-right: var(--f24);
}

.confirmModalFooter {
  margin-top: var(--f0);
  padding-right: var(--f24);
}

.deleteModalFooter button,
.confirmModalFooter button {
  width: 104px;
  height: var(--f40);
  padding: 10px var(--f10);
  gap: var(--f8);
  width: auto;
  border-radius: var(--f12);
  border: 1px;
}

.deleteModalFooter button:nth-child(2),
.confirmModalFooter button:nth-child(2) {
  background: var(--white, #ffffff);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  font-size: var(--f14);
  font-weight: var(--fw700);
  color: var(--primary-black);
  box-shadow: 0px -4px 4px 0px #10182814 inset;
}

.deleteModalFooter button:nth-child(1),
.confirmModalFooter button:nth-child(1) {
  background: #e8450e;
  border: 1px solid var(--primary-border-color, #c35a1f);
  color: var(--white);
  font-weight: var(--fw600);
  width: auto;
  font-size: var(--f14);
  box-shadow: 0px -4px 4px 0px #1018281f inset;
}

.deleteModal .ant-modal-close,
.deleteModal .ant-modal-close:hover,
.confirmModal .ant-modal-close,
.confirmModal .ant-modal-close:hover {
  width: 24px !important;
  height: 24px !important;
  margin-top: var(--f10);
  margin-right: var(--f6);
  color: 1.5px solid var(--black-color, #000000);
  background-color: var(--white);
}

.template_modal .confirmModalFooter {
  margin-top: var(--f24);
}