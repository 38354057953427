.templateContainer {
  height: 100%;
}

.leftContainer,
.rightContainer {
  display: flex;
  background-color: var(--white, #fff);
  flex-direction: column;
  padding: var(--f24);
  min-height: 92vh;
  border-radius: var(--f16);
}

.leftContainer .ant-select-selector {
  border-radius: var(--f8) !important;
  border: 1px solid #d0d5dd !important;
  height: 56px !important;
  padding: 16px !important;
}

.leftContainer .ant-select-arrow svg {
  fill: #000000;
}

.selectMail {
  height: 58px;
}

.selectPopup {
  padding: var(--f0);
  gap: var(--f0);
  border-radius: var(--f8);
  border: 1px;
  background: var(--white, #fff);
  margin-top: var(--f6);
  border: 1px solid #d1d1d1;
  box-shadow: 8px 16px 56px -24px #b3b3b33d;
}

.formItem {
  width: 100%;
}

.ant-select-selection-placeholder {
  font-family: var(--font-satoshi);
  font-size: var(--f16);
  font-weight: var(--fw500);
  text-align: left;
  color: var(--gray-light-700) !important;
}

.selctBarFooter {
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #e8e8e8;
  margin-bottom: 8px;
  box-shadow: 0px -6px 24px 0px #34405405;
  column-gap: 8px;
}

.emailTemplateFooter {
  justify-content: flex-end;
  padding: 14px 16px;
  height: auto;
  margin-bottom: 0;
}

.selctBarFooter button {
  width: 94px;
  height: var(--f40);
  padding: 10px var(--f10);
  border-radius: var(--f8);
}

.selctBarFooter button:nth-child(1) {
  background: var(--white, #ffffff);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  font-size: var(--f14);
  font-weight: var(--fw700);
  line-height: var(--f24);
  color: var(--primary-black, #101828);
  display: flex;
  align-items: center;
  justify-content: center;
}

.selctBarFooter button:nth-child(2) {
  background-color: #f75f18;
  border: 1px solid #c35a1f;
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw600);
  line-height: var(--f20);
  color: var(--white, #ffffff);
  transition: 0.5s all;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.selctBarFooter button:nth-child(2):hover {
  background-color: #e8450e;
}

.template_submit_button {
  width: 107px !important;
  height: 40px !important;
  padding: 10px 14px 10px 14px !important;
  border-radius: var(--f8) !important;
  border: 1px 0px 0px 0px !important;
  background: #f75f18 !important;
  border: 1px solid #c35a1f !important;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset !important;
  transition: 0.5s all !important;
  color: white !important;
  cursor: pointer !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.template_submit_button:hover,
.selctBarFooter button:nth-child(2):hover {
  background-color: #e8450e !important;
}

.subjectInput,
.descriptionInput {
  width: 100% !important;
}

.subjectInput .ant-input {
  height: 58px;
  padding: 18px var(--f16);
  gap: var(--f4);
  border-radius: var(--f8);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: var(--font-satoshi);
  font-size: var(--f16);
  font-weight: var(--fw500);
  line-height: 21.6px;
  text-align: left;
  margin-bottom: var(--f4);
}

.descriptionInput {
  min-height: 600px !important;
}

.descriptionInput .ant-input {
  min-height: 600px !important;
  padding: 18px var(--f16);
  border-radius: var(--f8);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.descriptionInput .ck.ck-editor__editable_inline> :last-child,
.descriptionInput .ck.ck-editor__editable_inline> :first-child {
  margin-bottom: var(--f0) !important;
  margin-top: var(--f0) !important;
}

.subjectInput .ant-input::placeholder,
.descriptionInput .ck.ck-editor__editable>.ck-placeholder::before {
  font-family: var(--font-satoshi) !important;
  font-size: var(--f16) !important;
  font-weight: var(--fw500) !important;
  line-height: 21.6px;
  color: rgba(52, 64, 84, 0.5) !important;
}

.descriptionInput .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content,
.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content.ck-rounded-corners {
  border-radius: 8px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.selectList {
  display: flex;
  flex-direction: column;
  gap: var(--f8);
  margin-top: var(--f4);
}

.selectList div {
  height: var(--f30);
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: 50px;
  flex-direction: row;
  padding: 4px var(--f8);
  gap: var(--f8);
  border-radius: var(--f6);
  background: #eaeaea;
}

.selectList div span {
  font-size: var(--f16);
  font-weight: var(--fw500);
  line-height: 21.6px;
  color: #344054;
  /* display: inline-block;
  width: fit-content;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; */
}

.selectList div img {
  cursor: pointer;
}

.selectMailname {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw500);
  line-height: var(--f20);
  color: var(--gray-light-700, rgba(52, 64, 84, 1));
}

.selectMailtext {
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  font-weight: var(--fw400);
  line-height: var(--f20);
  text-align: left;
  color: var(--gray-light-600, rgba(71, 84, 103, 1));
  height: 20px;
  justify-content: center;
  display: flex;
}

.selectTemplateMailtext {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw400);
  line-height: var(--f20);
  text-align: left;
  color: #000000;
  height: 20px;
  justify-content: center;
  display: flex;
}

.selectMailtext svg {
  height: 16px;
  width: 16px;
  margin: auto;
  margin-left: 8px;
}

.selectPopup .selectPopupOption:first-child label span:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #344054;
}

.selectPopupOption {
  height: 54px;
  opacity: var(--f0);
  display: flex;
  align-items: center;
}

.mailTemplateOption {
  padding: 8px var(--f16) !important;
  height: 36px;
}

.addNewOption .ant-select-item-option-content {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
}

.addNewOption .ant-select-item-option-content span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #f75f18;
}

.selectPopupOption,
.selectPopupOption .ant-select-item-option-content,
.selectPopupOption label {
  width: 100% !important;
}

.selectPopupOption .ant-checkbox-inner {
  border-radius: 50% !important;
  width: 16.67px !important;
  height: 16.67px !important;
  border: 1.5px solid #10182880 !important;
  background-color: var(--white, #fff);
}

.selectPopupOption .ant-checkbox-checked .ant-checkbox-inner {
  background: rgba(247, 95, 24, 1) !important;
  border: 1.5px solid rgba(255, 255, 255, 1) !important;
  border-radius: 50% !important;
  width: 16.67px !important;
  height: 16.67px !important;
}

.selectPopupOption .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1) translate(-50%, -60%) !important;
  width: 4.714286px !important;
  height: 8.142857px !important;
}

.descriptionInput {
  margin-bottom: 0px !important;
}

.descriptionInput .ck.ck-editor__main>.ck-editor__editable,
.ck-source-editing-area {
  min-height: 550px !important;
  height: calc(70vh - 50px) !important;
  max-height: calc(70vh - 50px) !important;
  border-start-end-radius: 8px !important;
  border-start-start-radius: 8px !important;
  padding: var(--f16);
  box-shadow: none !important;
  overflow: auto;
  border-bottom: none;
}

.descriptionInput .ck.ck-editor__main>.ck-editor__editable:visited,
.ck-source-editing-area:visited,
.descriptionInput .ck.ck-editor__main>.ck-editor__editable:focus-visible,
.ck-source-editing-area:focus-visible {
  border-color: var(--ck-color-base-border);
}

.descriptionInput .ck .ck-toolbar__items {
  height: 50px;
  gap: var(--f16);
  background: #ffffff;
}

.ck.ck-toolbar {
  border-top: 1px solid #eaecf0 !important;
  border-bottom: 1px solid var(--gray-light-300, #d0d5dd) !important;
  border-radius: 8px !important;
}

.ck-reset_all,
.ck.ck-reset_all {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 0px;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: initial;
}

.ck.ck-content ul {
  padding-left: var(--f20);
}

.ck.ck-content ol {
  padding-left: var(--f20);
}

.ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by {
  display: none !important;
}

/* 
.ant-select-selection-item label {
  width: 100%;
}

.ant-select-selection-item label .select_item_content {
  display: flex;
  column-gap: 10px;
}

.ant-select-selection-item label .select_item_content span:first-child {
  display: none;
} */