/* * {
    background-color: rgba(16, 24, 40, 0.35) !important;
} */
.sourseModal .ant-modal-content {
  height: 578px !important;
  border-radius: 16px !important;
  padding: var(--f0);
}

.confirmModal .ant-modal-content {
  height: 260px;
  border-radius: 16px !important;
  padding: var(--f0);
}

.sourseModalContent {
  padding: 24px 24px 0px var(--f24);
}

.modalDesc {
  margin-top: var(--f20);
}

.modalHeader {
  width: 346px;
  height: var(--f56);
}

.confirmModalHeader {
  width: 346px;
  height: 52px;
}

.modalHeader span,
.confirmModalHeader span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw700);
  font-size: var(--f20);
  color: var(--gray-light-700);
}

.confirmModalHeader span {
  font-family: var(--font-epilogue);
}

.modalDesc {
  width: 402px;
  /* height: 82px; */
  display: flex;
  flex-direction: column;
  gap: var(--f20);
}

.modalDesc span {
  font-family: var(--font-satoshi);
  /* font-family: "Satoshi"; */
  font-size: var(--f16);
  font-weight: var(--fw500);
  color: var(--gray-light-700);
}

.sourseModalFooter,
.confirmModalFooter {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--f10);
  height: 88px;
  max-width: 1000px;
  align-items: center;
  border-top: 2px solid #f3f4f6;
  margin-top: var(--f24);
  padding-right: var(--f24);
}

.confirmModalFooter {
  margin-top: var(--f0);
  padding-right: var(--f24);
}

.sourseModalFooter button,
.confirmModalFooter button {
  width: 104px;
  height: var(--f40);
  padding: 10px var(--f10);
  gap: var(--f8);
  border-radius: var(--f12);
  border: 1px;
}

.sourseModalFooter button:nth-child(2),
.confirmModalFooter button:nth-child(2) {
  background: var(--white, #ffffff);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  font-size: var(--f14);
  font-weight: var(--fw700);
  color: var(--primary-black);
}

.sourseModalFooter button:nth-child(1),
.confirmModalFooter button:nth-child(1) {
  border: 1px solid var(--primary-border-color);
  background-color: var(--primary-color);
  height: 42px;
  display: flex;
  align-items: center;
  color: var(--white, #ffffff);
  justify-content: center;
  border-radius: var(--f8);
  transition: 0.5s all;
  font-size: var(--f14);
  font-weight: var(--fw700) !important;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.sourseModalFooter>button:nth-child(1):hover {
  background-color: #e8450e;
}

.sourseModalFooter>button span {
  color: white;

  font-weight: var(--fw700) !important;
  font-size: var(--f16);
  line-height: 24px;
  text-wrap: nowrap;
}

.sourseModalFooter>button:disabled {
  border: 1px solid var(--gray-light-300);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  background-color: var(--white);
  height: 42px;
  display: flex;
  padding-right: 1.25rem;
  align-items: center;
  width: 100%;
  padding-inline: 24px;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.5s all;
}

.sourseModalFooter>button:disabled span {
  color: var(--gray-light-300);
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: 24px;
  font-family: var(--font-satoshi);
  text-wrap: nowrap;
}

.sourseModal .ant-modal-close,
.sourseModal .ant-modal-close:hover,
.confirmModal .ant-modal-close,
.confirmModal .ant-modal-close:hover {
  width: 24px !important;
  height: 24px !important;
  margin-top: var(--f10);
  margin-right: var(--f6);
  color: 1.5px solid var(--black-color, #000000);
  background-color: var(--white);
}

.sourseModalContent .ant-input {
  height: 378px;
}