.mainCardContainer {
  margin-top: var(--f20);
  box-shadow: var(--shadow) !important;
}
.cardTitleUsage {
  font-size: var(--f20);
  font-weight: var(--fw700);
  padding-left: var(--f22);
  font-family: var(--font-epilogue);
}
.mainContainer {
  display: flex;
  width: 100%;
  padding-left: var(--f24);
  padding-right: var(--f24);
  padding-bottom: var(--f24);
  padding-top: var(--f10);
  flex-direction: column;
}
.mainContainer label {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--f38);
  display: flex;
}
.mainContainer label span {
  font-size: var(--f14);
  font-weight: var(--fw400);
}
.mainprogress {
  height: var(--f10);
  width: 100%;
  border-radius: 9999px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  background: #eaecf0;
  border: #e5e7e9 1px solid;
}
.progress {
  height: var(--f10);
  width: 240px;
  border-radius: 9999px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  background-color: var(--primary-color);
}
@media screen and (max-width: 1200px) {
  .mainContainer {
    margin-top: var(--f10);
  }
}
