/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  font-family: "Epilogue", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: var(--f0);
}

html {
  overflow: hidden;
}

@font-face {
  font-family: "Satoshi-Variable";
  src: url("./fonts/Satoshi-Variable.woff2") format("woff2"),
    url("./fonts/Satoshi-Variable.woff") format("woff"),
    url("./fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

:root {
  --primary-color: #f75f18;
  --primary-border-color: #c35a1f;
  --gray-light-700: #344054;
  --gray-light-500: #667085;
  --gray-light-200: #eaecf0;
  --gray-light-300: #d0d5dd;
  --gray-light-400: rgba(152, 162, 179, 1);
  --gray-light-600: #475467;
  --gray-light-100: #f2f4f7;
  --gray-light-50: #f9fafb;
  --light-green-500: #ecfdf3;
  --light-green-700: #abefc6;
  --light-red-500: #fef3f2;
  --light-red-700: #f97066;
  --white: #fff;
  --black-color: #000000;
  --primary-black: #101828;
  --black-primary-900: #101828;
  --font-satoshi: "Satoshi-Variable", sans-serif;
  --font-epilogue: "Epilogue", sans-serif;
  --red-progress-100: #f52e2e;
  --red-progress-200: #e31f1f;
  --yellow-progress-100: #d37c0c;
  --green-progress-100: #00b926;
  --green-btn-text: #17b26a;
  ---Tag-Green-500: #049122;
  --shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  ---Tag-Green-500: #049122;
  --email-icone-border: #fdd0ab;
  --email-icone-background: #fff5ed;
  --shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --f0: 0px;
  --f1: 1px;
  --f2: 2px;
  --f3: 3px;
  --f4: 4px;
  --f5: 5px;
  --f6: 6px;
  --f7: 7px;
  --f8: 8px;
  --f8: 8px;
  --f9: 9px;
  --f10: 10px;
  --f11: 11px;
  --f12: 12px;
  --f13: 13px;
  --f14: 14px;
  --f15: 15px;
  --f16: 16px;
  --f17: 17px;
  --f18: 18px;
  --f19: 19px;
  --f20: 20px;
  --f21: 21px;
  --f22: 22px;
  --f24: 24px;
  --f25: 25px;
  --f26: 26px;
  --f28: 28px;
  --f30: 30px;
  --f32: 32px;
  --f34: 34px;
  --f38: 38px;
  --f40: 40px;
  --f36: 36px;
  --f44: 44px;
  --f48: 48px;
  --f46: 46px;
  --f56: 56px;
  --f60: 60px;
  --f64: 64px;
  --f70: 70px;
  --f72: 72px;
  --f80: 80px;
  --f104: 104px;
  --f110: 110px;
  --f125: 125px;
  --f136: 136px;
  --f170: 170px;
  --f180: 180px;
  --f420: 420px;
  --f600: 600px;
  --fw400: 400;
  --fw700: 700;
  --fw800: 800;
  --fw900: 900;
  --fw600: 600;
  --fw500: 500;
  --vh100: 100vh;
  --vh72: 72.2vh;
  --vh88: 88vh;
}

button {
  cursor: pointer;
}
.arc {
  background-color: red !important;
}

g {
  /* fill: #2b38b1 !important; */
  transition: fill 0.3s ease;
}
.pointer > path {
  /* fill: #b11c1c !important; */
  transition: fill 0.3s ease;
}
/* .pointer > path {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 5 0 C 3.333 -12.5 1.667 -25 0 -25 C -1.667 -25 -3.333 0 -5 0 C -3.333 0 -1.667 5 0 5 C 1.667 5 3.333 2.5 5 0");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 5 0 C 3.333 -12.5 1.667 -25 0 -25 C -1.667 -25 -3.333 0 -5 0 C -3.333 0 -1.667 5 0 5 C 1.667 5 3.333 2.5 5 0");
} */
a,
span,
div,
p,
label,
u,
b,
strong,
input,
button {
  font-family: var(--font-satoshi);
  font-optical-sizing: auto;
  font-style: normal;
}

.ant-input {
  font-family: var(--font-satoshi) !important;
  font-optical-sizing: auto;
  font-style: normal;
}

.ant-form-item-label > label {
  font-family: var(--font-satoshi) !important;
  font-weight: var(--fw500) !important;
  line-height: var(--f20) !important;
  font-size: var(--f14) !important;
  color: #344054 !important;
}

.ant-form-item-label {
  padding: 0 !important;
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-form-item input::placeholder,
.ant-form-item input {
  font-family: var(--font-satoshi) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.ant-form-item .ant-form-item-explain-error {
  font-family: var(--font-satoshi) !important;
}

.ant-form-item .ant-input-outlined {
  border: var(--f1) solid #d0d5dd !important;
  box-shadow: var(--f0) var(--f1) var(--f2) var(--f0) #1018280d !important;
  padding: var(--f8) var(--f12) !important;
}

.ant-form-item input::placeholder {
  color: #667085 !important;
}

.ant-form-item input {
  color: #101828 !important;
}
.ant-badge-dot {
  width: 12px !important;
  height: 12px !important;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within,
.ant-input-outlined:hover {
  border-color: #fcae75 !important;
  box-shadow: 0 0 0 4px #fcae7530 !important;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "" !important;
}

.ant-switch.ant-switch-checked {
  background-color: #f75f18 !important;
}

.connect-email-google-form .ant-form-item-required {
  width: 100% !important;
  display: flex !important;
}

.connect-email-google-form > div > div > label {
  width: 100% !important;
}

.connect-email-google-form .ant-form-item-required::after {
  display: none !important;
}

.connect-email-google-form
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.connect-email-google-form
  .ant-checkbox:not(.ant-checkbox-disabled):hover
  .ant-checkbox-inner {
  border-color: #f75f18 !important;
}

.connect-email-google-form .ant-checkbox .ant-checkbox-inner {
  border-radius: 50px;
}

.connect-email-google-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f75f18;
  border: 1px solid #f75f18 !important;
}

.connect-email-google-form
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.connect-email-google-form
  .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.connect-email-google-form
  .ant-checkbox-checked:not(.ant-checkbox-disabled):hover
  .ant-checkbox-inner {
  background-color: #f75f18;
}

.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector,
.ant-select-focused:where(
    .css-dev-only-do-not-override-11xg00t
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #f75f18 !important;
  box-shadow: 0 0 0 4px #fcae7530 !important;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f75f182e;
}

.ant-collapse-header {
  display: flex;
  align-items: center !important;
  padding: 24px;
}

.domainCollpseContainer > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center;
  background: white;
  border-radius: var(--f12);
  padding: 24px;
}

.domainCollpseContainer
  > .ant-collapse-item-active
  > .ant-collapse-content.ant-collapse-content-active
  > .ant-collapse
  > .ant-collapse-item {
  background: rgb(173, 23, 23) !important;
}

.ant-collapse > .ant-collapse-item:last-child {
  border-radius: 8px !important;
  border: 1px solid #eaecf0 !important;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header,
.ant-collapse > .ant-collapse-item {
  border-radius: 8px !important;
  border: 1px solid #eaecf0 !important;
}

.ant-collapse-content-box > .ant-collapse-content-active > .ant-collapse {
  background-color: red !important;
}

.domain_setup_nested_items > .ant-collapse > .ant-collapse-header {
  display: flex;
  align-items: center;
  background: rgb(197, 5, 5);
  border-radius: var(--f12);
}

.domainCollpseContainer > .ant-collapse-item > .ant-collapse-header:hover {
  display: flex;
  align-items: center;
  background: #f9fafb;
  border-radius: var(--f12);
}

.domainCollpseContainer > .ant-collapse-item-active > .ant-collapse-header {
  display: flex;
  align-items: center;
  background: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  /* border-radius: var(--f12); */
}

.domainCollpseContainer > .ant-collapse-item-active > .ant-collapse-content {
  background: #f9fafb;
  /* border-radius: var(--f12); */
}

.domainCollpseContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--f20);
}

.domainCollpseContainer > .ant-collapse-item {
  display: flex;
  flex-direction: column;
  row-gap: 20px !important;
  border: 1px solid #eaecf0;
  border-radius: var(--f8);
}

.ant-collapse
  > .ant-collapse-item-active
  > .ant-collapse-content
  .ant-collapse {
  display: flex;
  flex-direction: column;
  row-gap: 20px !important;
}

.ant-collapse
  > .ant-collapse-item-active
  > .ant-collapse-content
  .ant-collapse
  .ant-collapse-item {
  border: 1px solid #eaecf0;
  border-radius: var(--f8);
  background-color: white;
}

.ant-collapse {
  border: none !important;
}

.ant-collapse
  > .ant-collapse-item-active
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-header {
  padding: 2px 16px !important;
}

.ant-spin .ant-spin-dot-item {
  background-color: #fff5ed;
}

.ant-collapse-item-active .ant-collapse-expand-icon .anticon-right > svg {
  transform: rotate(0deg) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #e8450e !important;
  display: block;
}
.recharts-surface {
  width: 103% !important;
}

.filterCountMainContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  height: 14px;
}
.countContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  /* padding: 10px; */
}
.bt-spinner {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid transparent;
  border-top-color: #e8450e;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loaderContainer_warmup_setting {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #e8450e; */
}
.loaderContainer_warmup_setting .ant-spin .ant-spin-dot-item {
  background-color: #e8450e;
}
