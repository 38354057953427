.icone {
  height: var(--f60);
  width: var(--f60);
  border: 1.5px solid var(--email-icone-border);
  border-radius: var(--f12);
  background-color: var(--email-icone-background);
  justify-content: center;
  display: flex;
  align-items: center;
}

.googleComponent form {
  width: 100% !important;
}

.emailTypeName {
  font-size: var(--f26);
  margin-top: var(--f16);
  font-weight: var(--fw800);
  margin-bottom: var(--f12);
}

.description,
.description span {
  color: #10182880 !important;
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f14);
  line-height: var(--f20);
  padding-inline-start: var(--f4) !important;
  padding-inline-end: var(--f0) !important;
}

.description span span {
  border: 1.5px solid #10182880 !important;
  height: 13.33px !important;
  width: 13.33px !important;
}

.description span span::after {
  transform: rotate(45deg) scale(1) translate(-16%, -74%) !important;
  width: 3.714286px !important;
  height: 8.142857px !important;
}

.descriptionforConnect {
  color: #667085 !important;
  font-size: var(--f20);
  margin-bottom: var(--f40);
}

.checkBoxFieldLabel {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.buttonsContainer {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  column-gap: 16px;
  margin-top: 12px;
}

.cancelButton {
  border: 1px solid #d0d5dd;
  line-height: var(--f24);
  box-shadow: 0px -2px 2px 0px #1018281a inset;
  font-weight: var(--fw700);
  font-size: var(--f16);
  padding: var(--f10);
  width: 120px;
  cursor: pointer;
  height: 44px;
  display: flex;
  justify-content: center;
  border-radius: var(--f8);
  align-items: center;
  color: #101828;
}

.buttonsContainer button {
  color: white;
  line-height: var(--f24);
  font-weight: var(--fw700);
  font-size: var(--f16);
  padding: var(--f10);
  width: 122px;
  height: 44px;
  background-color: #f75f18;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid #c35a1f;
  transition: 0.5s all;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  border-radius: var(--f8);
}

.googleInfoComponent {
  top: var(--f40);
  right: var(--f24);
  height: auto;
  position: absolute;
  display: flex;
  width: 277px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.googleComponent {
  overflow: scroll;
  background-color: white;
  border-radius: 0.75rem;
  align-items: center;
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
}

.googleFromSection {
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 28%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: var(--f40);
}

.googleComponent button:hover {
  background-color: #e8450e;
}

.googleInfoComponent div:nth-child(2) {
  column-gap: var(--f5);

  width: 100%;
  display: flex;
  align-items: center;
}

.googleInfoComponent div:nth-child(2) button {
  color: white;
  line-height: var(--f24);
  font-size: var(--f16);
  font-weight: var(--fw700);
  padding-top: var(--f10);
  background-color: #f75f18;
  border: 1px solid #c35a1f;
  transition: 0.5s all;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  padding-bottom: var(--f10);
  border-radius: var(--f8);
  margin-top: var(--f8);
  width: var(--f136);
}

.googleInfoComponent div:nth-child(2) button:hover {
  background-color: #e8450e;
}

.googleInfoComponent .instractions {
  border: 1.25px solid #fdd0ab;
  background-color: #fff5ed;
  border-radius: var(--f12);
  display: flex;
  height: auto;
  min-height: 206px;
  width: 277px;
  flex-direction: column;
  align-items: center;
  padding: var(--f16);
  justify-content: flex-start;
}

.failInstractions {
  background-color: #ededed !important;
  border: 1px solid #f04438 !important;
}

.googleInfoComponent .instractions label {
  color: #1f2937;
  margin-bottom: var(--f4);
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  font-weight: var(--fw500);
  line-height: var(--f18);
}

.googleComponent::-webkit-scrollbar-track {
  background-color: transparent;
}

.googleComponent::-webkit-scrollbar {
  width: var(--f6);
  background-color: transparent;
}

.googleComponent::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@media screen and (max-width: 1400px) {
  .googleInfoComponent .instractions {
    padding: var(--f8);
  }

  .googleInfoComponent .instractions label {
    font-size: var(--f12);
    margin-bottom: var(--f2);
  }

  .googleFromSection {
    padding-top: 3rem;
  }

  .emailTypeName {
    font-size: var(--f20);
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .descriptionforConnect {
    font-size: var(--f18);
    margin-bottom: var(--f16);
  }
}

.googleInfoComponent .instractions label:last-child {
  margin-bottom: var(--f0);
}

.googleInfoComponent .instractions span {
  font-size: var(--f12);
  line-height: var(--f18);
  color: #1f2937;
  align-items: flex-start;
  display: flex;
  width: 100%;
  font-weight: var(--fw900);
  margin-bottom: var(--f8);
}

.successComponent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--f14);
  column-gap: var(--f6);
  line-height: var(--f20);
  font-weight: var(--fw500);
  width: 100%;
  margin: var(--f4) var(--f0);
}

.successComponent .success {
  color: #17b26a;
}

.successComponent .fail {
  color: #da6868;
}

.notificationText {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw700);
  line-height: var(--f20);
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close {
  width: var(--f48);
  height: var(--f48);
  background-color: #f2fbf4 !important;
  top: var(--f0);
  right: var(--f0);
}

.custom-notification .anticon svg {
  width: var(--f12);
  height: var(--f12);
  color: var(--gray-light-500, #667085);
}

.startBorder {
  width: 5px;
  min-height: 100%;
  top: var(--f0);
  left: var(--f0);
  border-radius: 4px 0px 0px var(--f4);
  position: absolute;
  background: var(--Tag-Green-500, #049122);
}
