.btn {
  padding: 4px var(--f10) var(--f4) var(--f6);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #abefc6;
  background-color: var(--light-green-500);
  color: #17b26a;
  gap: var(--f6);
  border-radius: 9999px;
  font-weight: var(--fw500);
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  line-height: var(--f18);
}

.btn > span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f12);
  line-height: var(--f18);
  color: var(--green-btn-text);
}
.redbtn {
  border: 1px solid #efabd3 !important;
  background-color: #ffe1e1 !important;
  color: #e31f1f !important;
  padding: 4px var(--f10) var(--f4) var(--f6);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--f6);
  border-radius: 9999px;
  font-weight: var(--fw500);
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  line-height: var(--f18);
}
.warningBtn {
  border: none !important;
  background-color: #ffe494a6 !important;
  color: #192435 !important;
  padding: 4px var(--f10) var(--f4) var(--f6);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--f5);
  border-radius: 9999px;
  font-weight: var(--fw500);
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  line-height: var(--f18);
}
.warningBtn > span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f12);
  line-height: var(--f18);
  /* color: var(--red-progress-100); */
}
.redbtn > span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f12);
  line-height: var(--f18);
  color: var(--red-progress-100);
}
