.icone {
  height: 60px;
  width: 60px;
  border: 1px solid #fdd0ab;
  border-radius: var(--f12);
  background-color: #fff5ed;
}

.icone svg {
  position: relative;
  top: var(--f14);
  left: var(--f14);
}

.title,
.title span {
  color: #10182880 !important;
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f12);
  text-wrap: nowrap;
  line-height: var(--f20);
  padding-inline-start: var(--f4) !important;
  padding-inline-end: var(--f0) !important;
}

.title_user_name span {
  color: #344054 !important;
}

.title span span {
  border: 1.5px solid #10182880 !important;
  height: 13.33px !important;
  width: 13.33px !important;
}

.title span span::after {
  transform: rotate(45deg) scale(1) translate(-16%, -74%) !important;
  width: 3.714286px !important;
  height: 8.142857px !important;
}

.checkBoxFieldLabel {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.checkBoxFieldLabel>span {
  text-wrap: nowrap;
  font-size: var(--f12);
}

.googleInfoComponent {
  top: var(--f40);
  right: var(--f24);
  height: auto;
  position: absolute;
  display: flex;
  width: 277px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.googleComponent {
  background-color: white;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  overflow: auto;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vh - 224px);
}

.googleComponent form {
  width: 100% !important;
  overflow: hidden;
}

.encryptionFormItem {
  margin-bottom: var(--f18);
}

.encryptionFormItem .smtpButtons label span:nth-child(2) {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #667085;
}

.encryptionFormItem .smtpButtons div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--f20);
}

.encryptionFormItem .smtpButtons div .button {
  border: 1px solid #d0d5dd;
  height: var(--f40);
  width: 147px;
  border-radius: var(--f8);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: var(--f12);
  cursor: pointer;
}

.encryptionFormItem .smtpButtons div .button.selected {
  border: 1px solid #f75f18;
}

.encryptionFormItem .smtpButtons div .button.selected span:nth-child(2) {
  color: #f75f18;
}

.formComponent {
  overflow-x: hidden;
  margin-bottom: auto;
  margin-top: var(--f8);
  display: flex;
  width: 32%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
}



.headings {
  margin-bottom: var(--f15);
  margin-top: var(--f32);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  column-gap: var(--f6);
}

.headings span:first-child {
  font-size: var(--f16);
  line-height: var(--f16);
  font-weight: var(--fw700);
  font-family: var(--font-epilogue);
}

.headings span:last-child {
  font-weight: var(--fw400);
  font-family: var(--font-epilogue);
  line-height: var(--f16);
  font-size: var(--f16);
}

.googleComponent button:hover {
  background-color: #e8450e;
}

.googleInfoComponent div:nth-child(2) {
  width: 100%;
  display: flex;
  column-gap: var(--f5);
  align-items: center;
}

.googleInfoComponent div:nth-child(2) button {
  color: white;
  line-height: var(--f24);
  font-size: var(--f16);
  font-weight: var(--fw700);
  padding-top: var(--f10);
  background-color: #f75f18;
  padding-bottom: var(--f10);
  border-radius: var(--f8);
  transition: 0.5s all;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  border: 1px solid #c35a1f;
  margin-top: var(--f8);
  width: var(--f136);
}

.googleInfoComponent div:nth-child(2) button:hover {
  background-color: #e8450e;
}

.googleInfoComponent .googleInfo {
  border: 1.25px solid #fdd0ab;
  background-color: #fff5ed;
  border-radius: var(--f12);
  padding: var(--f16);
  width: 277px;
  flex-direction: column;
  display: flex;
  height: auto;
  min-height: 206px;
  align-items: center;
  justify-content: flex-start;
}

.failInstractions {
  background-color: #ededed !important;
  border: 1px solid #f04438 !important;
}

.googleInfoComponent .googleInfo label {
  color: #1f2937;
  margin-bottom: var(--f4);
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  font-weight: var(--fw500);
  line-height: var(--f18);
}

.googleInfoComponent .googleInfo label:last-child {
  margin-bottom: var(--f0);
}

.googleInfoComponent .googleInfo span {
  font-size: var(--f12);
  line-height: var(--f18);
  color: #1f2937;
  align-items: flex-start;
  display: flex;
  width: 100%;
  font-weight: var(--fw900);
  margin-bottom: var(--f8);
}

.successComponent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--f14);
  column-gap: var(--f6);
  line-height: var(--f20);
  font-weight: var(--fw500);
  width: 100%;
  margin: var(--f4) var(--f0);
}

.successComponent .success {
  color: #17b26a;
}

.successComponent .fail {
  color: #da6868;
}

.cancelButton {
  border: 1.5px solid #d0d5dd;
}

.middleLine {
  background-color: #d0d5dd;
  height: var(--f1);
  width: 100%;
  margin-top: var(--f32);
}

.googleComponent::-webkit-scrollbar-track {
  background-color: transparent;
}

.googleComponent::-webkit-scrollbar {
  width: var(--f6);
  background-color: transparent;
}

.googleComponent::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.submitButtom {
  background-color: white;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
  bottom: var(--f0);
  display: flex;
  box-shadow: 0px -2px 10px 0px #04154c0f;
  height: 76px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: var(--f16) var(--f24);
  column-gap: 16px;
}

.submitButtom button {
  background-color: transparent !important;
  border: 1.5px solid #d0d5dd;
  line-height: var(--f24);
  font-weight: var(--fw700);
  width: 120px;
  padding: var(--f10);
  font-size: var(--f16);
  cursor: pointer;
  border-radius: var(--f8);
  display: flex;
  height: 44px;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d5dd;
  box-shadow: 0px -2px 2px 0px #1018281a inset;
  color: #101828;
}

.submitButtom button:last-child {
  background-color: #f75f18 !important;
  transition: 0.5s all;
  color: white;
  border: 1px solid #c35a1f;
  width: 190px;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.submitButtom button:last-child:hover {
  background-color: #e8450e !important;
}

@media screen and (max-width: 1400px) {
  .submitButtom {
    height: var(--f70);
  }

  .submitButtom button {
    width: 140px;
    font-size: var(--f14);
    padding-top: var(--f10);
    padding-bottom: var(--f10);
  }

  .googleComponent {
    height: calc(75vh - 74px);
  }

  .googleInfoComponent .googleInfo {
    padding: 7px;
  }

  .googleInfoComponent .googleInfo span {
    font-size: var(--f12);
    margin-bottom: 1px;
  }

  .googleInfoComponent .googleInfo label {
    font-size: 11.5px;
    margin-bottom: 1px;
  }

  .googleInfoComponent .googleInfo label:last-child {
    margin-bottom: var(--f0);
  }

  .googleInfoComponent .googleInfo {
    top: var(--f16);
    right: var(--f16);
  }

  .successComponent {
    font-size: 11px;
  }
}

@media (1000px <= width <= 1200px )  {
  .checkBoxFieldLabel>span {
    text-wrap: nowrap;
    font-size: var(--f9);
  }
  .title span {
    text-wrap: nowrap;
    font-size: var(--f9);
  }
}