.warmupSettingContainer {
  /* height: 100%; */
  position: relative;
}

.modalTitle {
  /* padding: 32px 0px 0px 32px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customTab {
  margin: 15px 0px;
}

.warmpSettingModal .ant-modal-content {
  padding: 0px !important;
}

.modalTitle span {
  font-family: var(--font-epilogue);
  font-size: 20px;
  font-weight: var(--fw700);
  color: var(--gray-light-700);
}

/* .modalContent {
  padding: var(--f0) var(--f32) var(--f16) var(--f32);
} */

.emailText {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  color: var(--gray-light-700);
  font-weight: var(--fw400);
  text-align: center;
}

.dateContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--f12);
}

.dateContainer span {
  font-family: var(--font-satoshi);
  font-size: 14px;
  color: var(--gray-light-700);
  font-weight: var(--fw400);
}

.dateContainer span span {
  font-weight: var(--fw700);
}

.customTab .ant-tabs-tab-btn {
  font-family: var(--font-satoshi);
  font-size: var(--f16);
  font-weight: var(--fw600);
  color: var(--gray-light-500) !important;
  height: var(--f38);
}

.customTab .ant-tabs-nav .ant-tabs-nav-list {
  height: var(--f38);
}

.tabText {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f14);
}

.formLabel {
  display: flex;
  min-width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-family: var(--font-satoshi) !important;
  font-weight: var(--fw500);
  color: var(--gray-light-700) !important;
  font-size: var(--f12);
}

.formLabel span:nth-child(2) {
  font-family: var(--font-satoshi);
  font-size: var(--f10);
  line-height: var(--f20);
  color: var(--gray-light-400);
  font-weight: var(--fw500);
}

.customeInput {
  height: var(--f40);
  width: 333px;
  margin-top: 6px;
  border: 1px solid var(--gray-light-200);
  font-family: var(--font-satoshi);
  font-weight: var(--fw400);
  font-size: var(--f14);
  color: var(--gray-light-700);
  display: flex;
  align-items: center;
}

.customeInput .ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}

.ant-input-affix-wrapper.customeInput > input.ant-input#tab1_minimum,
.ant-input-affix-wrapper.customeInput > input.ant-input#tab1_maximum,
.customeInput .ant-input {
  border: none !important;
}

.customeInput .ant-input:hover {
  border: none !important;
  box-shadow: none !important;
}

.radioGroup {
  margin-top: 6px;
}

.radioGroup .ant-col {
  height: 40px !important;
  /* width: 170px; */
}

.radioGroup .ant-radio-wrapper {
  font-family: var(--font-satoshi);
  border: 1px solid var(--gray-light-200);
  width: 100%;
  padding: 7px;
  line-height: 16.2px;
  border-radius: var(--f8);
  height: var(--f40);
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.radioGroup .ant-radio-wrapper:hover {
  border: 1px solid var(--gray-light-500);
}

.radioGroup
  .ant-radio-wrapper
  .ant-radio-wrapper-checked
  .ant-radio-wrapper-in-form-item {
  border: 1px solid var(--primary-color) !important;
}

.radioGroup span {
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  font-weight: var(--fw500);
  color: var(--gray-light-700);
}

.weekdayContainer {
  height: 88px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border: 1px solid var(--gray-light-200);
  border-radius: var(--f8);
  padding: 10px 14px !important;
}

.weekText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.weekText span:nth-child(1) {
  font-family: var(--font-satoshi);
  font-weight: var(--fw700);
  font-size: var(--f14);
  color: var(--primary-black);
  line-height: var(--f20);
}

.weekText span:nth-child(2) {
  font-family: var(--font-satoshi);
  font-weight: var(--fw400);
  font-size: var(--f14);
  color: var(--gray-light-700);
}

.swichContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--f16);
  padding-right: 15px;
}

.swichContainer span {
  display: flex;
  align-items: center;
  color: #d1d1d1;
}

.swichContainer .ant-switch {
  width: 36px !important;
}

.buttonContainer {
  /* max-width: 746px; */
  display: flex;
  flex-direction: row-reverse;
  gap: var(--f16);
  align-items: center;
  padding-right: 24px;
  border-top: 2px solid var(--gray-light-200);
  height: 92px;
  box-shadow: 0px -6px 24px 0px #34405405;
}

.buttonContainer button:nth-child(1) {
  width: 120px;
  height: 44px;
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: var(--f24);
  color: var(--white) !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  border: 1px solid #c35a1f;
  background: #f75f18;
  justify-content: center;
  padding: var(--f10);
  transition: 0.5s all;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
}

.buttonContainer button:nth-child(1):hover {
  background: #e8450e;
}

.buttonContainer button:nth-child(2) {
  width: 120px;
  height: 44px;
  font-weight: var(--fw700);
  font-size: var(--f16);
  line-height: var(--f24);
  color: var(--black-primary-900) !important;
  background-color: var(--white);
  border-radius: 6px;
  border: 1px solid var(--gray-light-300);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--f10);
}

.templateSelect {
  height: auto;
  min-height: var(--f40);
  width: 100%;
  margin-bottom: 0px;
}

.emailTemplate {
  min-height: var(--f40);
  max-height: auto;
}

.templateSelect .ant-select-selector:hover {
  border: 1px solid var(--gray-light-500) !important;
}

.templateSelect .ant-select-selector {
  border: 1px solid var(--gray-light-200, #eaecf0) !important;
}

.templateSelect .ant-select-dropdown {
  height: 150px !important;
  width: 100%;
  height: 156px;
  padding: 8px 0px 8px var(--f0);
  gap: 4px;
  border-radius: var(--f8);
  border: 1px solid var(--gray-light-200, #eaecf0) !important;
  box-shadow: 8px 16px 56px -24px #b3b3b33d;
  box-shadow: 2px 2px 16px -3px #0000001f;
}

.templateSelect .ant-select-selection-placeholder {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw400);
  line-height: 24px;
  color: var(--gray-light-400) !important;
}

.templateListPopup .ant-select-item-option-selected {
  background: #f6f6f6 !important;
}

.templateListPopup {
  padding: 8px 0px 8px var(--f0);
  gap: 4px;
  border-radius: var(--f8);
  border: 1px solid var(--gray-light-300, #d0d5dd);
  box-shadow: 8px 16px 56px -24px #b3b3b33d;
  /* box-shadow: 2px 2px 16px -3px #0000001F; */
}

.templateSelect .ant-select-selection-item img {
  display: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn span {
  color: var(--primary-color) !important;
}

.ant-tabs-nav .ant-tabs-ink-bar {
  background-color: var(--primary-color);
}

.formLabel img {
  height: fit-content;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: var(--primary-color) !important;
}

.ant-radio-checked .ant-radio-inner {
  background-color: var(--white) !important;
  border-color: var(--primary-color) !important;
}

.ant-radio-inner {
  border-color: rgba(102, 112, 133, 1) !important;
}

.ant-select.ant-select-selector:hover {
  border-color: var(--primary-color) !important;
}

.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector,
.ant-select-focused:where(
    .css-dev-only-do-not-override-11xg00t
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 4px #fcae7530 !important;
}

.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 4px #fcae7530 !important;
}

.ant-radio-wrapper span.ant-radio {
  font-family: "satoshi" !important;
}

.form .ant-input {
  height: var(--f40);
  margin-top: 6px;
  border: 1px solid var(--gray-light-200);
  font-family: var(--font-satoshi) !important;
  font-weight: var(--fw400) !important;
  font-size: var(--f14) !important;
  color: var(--gray-light-700) !important;
}

.form .ant-input:hover,
.form .ant-input:focus {
  box-shadow: none;
  border: 1px solid var(--gray-light-200);
}

.modeSelect option {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw400);
  color: var(--black-color);
}

.modeSelect {
  height: var(--f40);
  margin-top: 6px;
}

.modeSelect .ant-select-selector {
  border: 1px solid var(--gray-light-200) !important;
  border-radius: 8px !important;
}

.modeSelect.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector,
.ant-select-focused:where(
    .css-dev-only-do-not-override-11xg00t
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border: 1px solid var(--gray-light-500) !important;
  box-shadow: none !important;
}

.modeSelect .ant-select-selection-item {
  font-family: var(--font-satoshi);
  font-weight: var(--fw400);
  font-size: var(--f14);
  color: var(--gray-light-700);
}

.modeSelectOption .ant-select-item-option-selected {
  background-color: #f6f6f6 !important;
  font-weight: var(--fw400) !important;
  color: var(--black-color) !important;
  font-size: var(--f14);
}

.modeSelect .ant-select-arrow {
  color: var(--gray-light-500, #667085) !important;
}

.summaryDiv {
  display: flex;
  flex-direction: row;
  /* width: 634px; */
  gap: var(--f8);
}

.countDiv {
  max-height: 124px;
  width: 206px;
  border-radius: var(--f12);
  padding: var(--f16);
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(234, 236, 240, 0.5);
}

.countText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.countText span:nth-child(1) {
  font-family: var(--font-epilogue);
  font-weight: var(--fw700);
  font-size: var(--f20);
  color: var(--gray-light-700);
}

.countText span:nth-child(2) {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f14);
  color: var(--gray-light-700);
}

.analyticsDiv {
  max-height: 204px;
  border: 1px solid var(--gray-light-200);
  padding: 24px;
  border-radius: var(--f16);
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.analyticsDiv span {
  font-family: var(--font-epilogue);
  font-weight: var(--fw700);
  font-size: var(--f16);
  color: var(--gray-light-700);
}

.warmupInsightsDiv {
  /* max-width: 682px; */
  max-height: 363px;
  border-radius: var(--f16);
  border: 1px solid var(--gray-light-200);
  margin-top: 20px;
  padding: 24px;
}

.warmupChartDiv {
  display: flex;
  align-items: center;
  height: var(--f40);
}

.warnupinsighText span {
  font-family: var(--font-epilogue) !important;
  font-weight: var(--fw700);
  font-size: var(--f16);
  color: var(--gray-light-700);
}

.chartFilter {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.chartFilter span:nth-child(1) {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  color: var(--gray-light-700);
  font-size: var(--f12);
}

.chartFilter .ant-select {
  width: 128px;
  height: var(--f40);
}

.chartFilter .ant-select .ant-select-arrow .anticon {
  width: 20px;
  height: 20px;
  color: var(--gray-light-500) !important;
}

.chartFilter option {
  font-family: var(--font-satoshi);
  font-weight: var(--fw400);
  font-size: var(--f14);
  color: var(--black-color);
}

.chartFilter
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector,
.ant-select-focused:where(
    .css-dev-only-do-not-override-11xg00t
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border: 1px solid var(--gray-light-500) !important;
  box-shadow: none !important;
}

.custom-dropdown .ant-select-item-option-selected {
  background-color: #f0f0f0 !important;
}

.chartFilter .ant-select-selection-item {
  font-family: var(--font-satoshi) !important;
  font-weight: var(--fw400);
  font-size: var(--f14);
  color: var(--gray-light-700);
}

.filterDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 15px;
  max-height: 43px;
}

.filterDiv div {
  background-color: var(--gray-light-50);
  border: 1px solid var(--gray-light-100);
  border-radius: var(--f12);
  height: 100%;
  display: flex;
  align-items: center;
  padding: 4px;
}

.filterDiv button {
  padding: 8px var(--f12);
  height: 35px;
  border: none !important;
  background-color: var(--gray-light-50);
}

.filterDiv span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f14);
  color: var(--gray-light-700);
}

.activeButton {
  box-shadow: 0px 1px 2px 0px #1018280f;
  background: var(--white, #ffffff) !important;
  box-shadow: 0px 1px 3px 0px #1018281a;
  border-radius: 6px;
}

.activeButton span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw700);
  font-size: var(--f14);
  color: var(--gray-light-700) !important;
}

.filterCountDiv {
  margin-top: 20px;
  height: 24px;
  display: flex;
  flex-direction: row;
  gap: var(--f8);
}

.filterCountDiv div {
  padding: 4px var(--f8);
  display: flex;
  height: 24px;
  align-items: center;
  border: 1px solid var(--gray-light-200);
  border-radius: 90px;
  gap: 4px;
}

.filterCountDiv div span:nth-child(3) {
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  font-weight: var(--fw700);
  line-height: 16.2px;
}

.filterCountDiv div:nth-child(1) span:nth-child(1) {
  width: var(--f8);
  height: var(--f8);
  background-color: #09de35;
  border-radius: 50%;
}

.filterCountDiv div:nth-child(2) span:nth-child(1) {
  width: var(--f8);
  height: var(--f8);
  background-color: #eea212;
  border-radius: 50%;
}

.customeToolTip {
  width: 160px;
  height: auto;
  border-radius: 4px;
  padding: 6px var(--f8);
  border: 1px solid var(--gray-light-200);
  background: var(--white, #ffffff);
  display: flex;
  flex-direction: column;
  gap: var(--f8);
  box-shadow: 8px 16px 56px -24px #b3b3b33d;
  box-shadow: 2px 2px 16px -3px #0000001f;
}

.customeToolTip .ant-row span:nth-child(1) {
  font-family: var(--font-satoshi);
  font-weight: var(--fw500);
  font-size: var(--f12);
  color: var(--gray-light-500);
}

.customeToolTip .ant-row span:nth-child(2) {
  font-family: var(--font-satoshi);
  font-weight: var(--fw700);
  font-size: var(--f12);
  color: var(--gray-light-700);
}

.inputSuffix {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw400);
  color: var(--gray-light-400);
}

.rangeMid {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--f72);
  padding-top: var(--f8);
}

.rangeMid span {
  font-family: var(--font-satoshi);
  font-weight: var(--fw400);
  font-size: var(--f14);
  color: var(--gray-light-700);
}

.templateListDropDown {
  width: 100%;
  /* height: 34px !important; */
  padding: 7px var(--f0);
  gap: 15px;
  border-radius: 5px;
  background: var(--gray-light-100, #f2f4f7);
}

.templateListDropDown:hover,
.templateListDropDown:focus,
.templateListDropDown.ant-input-affix-wrapper {
  background: var(--gray-light-100, #f2f4f7) !important;
  border: none !important;
  box-shadow: none !important;
}

.templateListDropDown
  :where(.css-dev-only-do-not-override-11xg00t).ant-input-affix-wrapper
  > input.ant-input {
  font-family: var(--font-satoshi) !important;
  font-size: var(--f14) !important;
  font-weight: var(--fw400);
  color: var(--gray-light-700) !important;
}

.addTemplateBox {
  display: flex;
  flex-direction: row;
  padding: 7px var(--f12);
  align-items: center;
  gap: var(--f8);
  margin-top: var(--f0);
  cursor: pointer;
}

.addTemplateBox span {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw400);
  line-height: 20px;
  text-align: left;
  color: var(--primary-color);
}

.optionBox {
  display: flex;
  flex-direction: row;
  gap: var(--f8);
  align-items: center;
  /* padding: 7px var(--f16); */
}

.noDataBox {
  background: var(--white, #ffffff) !important;
}

.noDataBox .ant-select-item-option-content {
  padding: 7px var(--f16);
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.noDataBox .ant-select-item-option-content span {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw400);
  line-height: 20px;
}

.optionBox span {
  font-family: var(--font-satoshi);
  font-size: var(--f14);
  font-weight: var(--fw400);
  line-height: 20px;
  text-align: left;
}

.templateList {
  max-height: 102px;
  overflow-y: auto;
}

.warmpSettingModal {
  position: absolute;
  top: 20px;
  right: 20px;
}

.warmpSettingModal .ant-modal-close,
.warmpSettingModal .ant-modal-close:hover {
  width: 24px;
  height: 24px;
  margin-top: 10px;
  margin-right: 6px;
  color: var(--primary-black, #000000);
  background-color: var(--white);
}

.recharts-yAxis .recharts-cartesian-axis-tick line {
  display: none;
}

.recharts-xAxis .recharts-cartesian-axis-tick line {
  height: 6px !important;
}

.recharts-layer tspan {
  font-family: var(--font-satoshi);
  font-size: var(--f12);
  font-weight: var(--fw600);
  color: var(--black-color, #000000);
  line-height: 16.2px;
}

.dashboard_email_capcha_form_item .ant-input-affix-wrapper {
  background: transparent !important;
  cursor: pointer !important;
  width: 100%;
}

.dashboard_email_capcha_form_item .ant-spin {
  color: #f75f187d;
}

.warm_up_mode_container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 16px;
  column-gap: 15px;
  overflow: auto;
  width: 100%;
}

.warm_up_mode_container .warm_up_mode_container_component_svg {
  margin: 16px;
}

.warm_up_mode_container_component {
  /* width: 217px; */
  width: 100%;
  height: 232px;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;
  transition: 0.5s all;
}

.warm_up_mode_container_component_selected {
  background: linear-gradient(129.81deg, #fff3ed 0%, #ffffff 31.94%);
  border: 1px solid #f75f18;
}

.warm_up_mode_container_component span {
  font-family: var(--font-epilogue);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  column-gap: 4px;
  margin: 0 16px;
}

.warm_up_mode_container_component > span > span {
  margin: 0 4px;
}

.warm_up_mode_container_component div:nth-child(3) {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.2px;
  color: #667085;
  margin: 8px 16px;
}

.update_subscription {
  font-weight: var(--fw700);
  font-size: var(--f14);
  line-height: 20px;
  color: #f04438;
}

.update_subscription:hover {
  font-weight: var(--fw700);
  font-size: var(--f14);
  line-height: 20px;
  color: #f04438;
}

.warm_up_mode_container_component label {
  width: auto;
  height: 20px;
  padding: 4px 6px 4px 6px;
  border-radius: 60px;
  border: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 700;
  line-height: 12px;
  background: #f9fafb;
  margin-left: 16px;
  color: #667085;
  cursor: pointer;
  position: absolute;
  transition: 0.5s all;
  bottom: 16px;
}

.warm_up_mode_container_component_selected label {
  border: 1px solid #abefc6;
  background: #ecfdf3;
  color: #079455;
}

.customTab #rc-tabs-0-panel-2 {
  height: 64vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 22px;
}

.customTab #rc-tabs-0-panel-2::-webkit-scrollbar-track {
  background-color: transparent;
}

.customTab #rc-tabs-0-panel-2::-webkit-scrollbar {
  width: var(--f0);
  background-color: transparent;
}

.customTab #rc-tabs-0-panel-2::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.ant-drawer .ant-drawer-content-wrapper {
  margin: 20px;
}

.ant-drawer .ant-drawer-content-wrapper .ant-drawer-content {
  border-radius: 15px;
}

.header_contaienr {
  display: flex;
  flex-direction: column;
}

.ant-drawer .ant-drawer-body {
  padding: 32px 32px 24px 32px;
}

@media (423px <=width <=1240px) {
  .ant-drawer .ant-drawer-body {
    padding: 24px 24px 20px 24px;
  }

  .radioGroup span {
    font-size: var(--f8);
  }
}

.titleContainerICons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}

.validate_message {
  color: #ff4d4f;
}
.upgradePlanBtn {
  display: grid;
  place-content: center;
  height: 64dvh;
}
.add-button-container button {
  background-color: rgb(247 95 24 / 1);
  align-items: center;
  width: 173px;
  border: 1px solid #c35a1f;
  justify-content: center;
  height: var(--f40);
  border-width: 1px;
  border-radius: var(--f6);
  display: flex;
  text-align: center;
  box-shadow: 0px 3px 2px 0px #ffffff3d inset;
  transition: 0.5s all;
}
.add-button-container button span {
  line-height: var(--f20);
  color: white;
  font-size: var(--f14);
  font-family: var(--font-satoshi);
  font-weight: var(--fw700);
}
.add-button-container h2 {
  text-align: center;
  margin: 0;
}
.add-button-container p {
  text-align: center;
}
.add-button-container button {
  text-align: center;
}
.add-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formLabel > span {
  display: flex;
  gap: 2px;
}
.icons {
  padding-top: 3px;
}
